import React, { useContext } from 'react';

import NewCategory from './NewCategory';

import { AdminContext } from '../../../../context/AdminContext';

import { deleteBlogCategory } from '../../../../requests/admin/blog';
import { simpleConfirmDialog } from '../../../../helpers/admin/msgHelper';

import {
    Button, Dialog, DialogActions, DialogContent,
    DialogTitle, List, ListItem, IconButton, Avatar,
    ListItemAvatar, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

const CategoryDialog = ({ open, setOpen, selectedCategory, setSelectedCategory }) => {
    const matches = useMediaQuery('(max-width:600px)');

    const { data: { categories }, setData } = useContext(AdminContext);

    // eliminar categoría
    const deleteCategory = category => async () => {
        if (await simpleConfirmDialog(`¿Eliminar categoría ${category.category}?`, 'Sí, continuar')) {
            await deleteBlogCategory(category, selectedCategory, setSelectedCategory, setData);
        }
    };

    return (
        <Dialog
            open={ open }
            onClose={ () => setOpen(false) }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ zIndex: 500 }}
            PaperProps={{ sx: { width: matches ? '100%' : '30%' } }}>
            <DialogTitle id="alert-dialog-title">
                Categorías de blog
            </DialogTitle>
            <DialogContent>
                <Demo>
                    <List>
                        {
                            categories.map(({ category_id, category }) =>
                                <ListItem
                                    key={ category_id }
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete"
                                            onClick={ deleteCategory({
                                                category_id, category
                                            }) }>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                    >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={ category }
                                    />
                                </ListItem>
                            )
                        }
                    </List>
                </Demo>

                <NewCategory
                    setSelectedCategory={ setSelectedCategory }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => setOpen(false) }>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CategoryDialog;