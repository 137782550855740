import { Routes, Route, Navigate } from 'react-router-dom';

//Components

import BlogMain from '../Components/Admin/Blog/BlogMain';
import Banners from '../Components/Admin/Banners/Banners';
import UserList from '../Components/Admin/AdminUsers/UserList';
import BlogPost from '../Components/Admin/Blog/BlogPost/BlogPost';
import IndexAdmin from '../Components/Admin/IndexAdmin/IndexAdmin';
import HeaderAdmin from '../Components/Admin/HeaderAdmin/HeaderAdmin';
import BlogUpdate from '../Components/Admin/Blog/BlogUpdate/BlogUpdate';
import BlogPreview from '../Components/Admin/Blog/BlogPreview/BlogPreview';
// import ResponsiveDrawer from '../components/Admin/ResponsiveDrawer';
// import DashboardComponent from '../components/Admin/Dashboard/DashboardComponent';

export const AdminRoutes = () => {
    return (
        <>
            <HeaderAdmin />
            <Routes>
                <Route path="/" element={<IndexAdmin />} />
                <Route path="/blog" element={<BlogMain />} />
                <Route path="/nuevo-blog" element={<BlogPost />} />
                <Route path="/vista-previa-blog" element={<BlogPreview />} />
                <Route path="/actualizar-blog/:url" element={<BlogUpdate />} />
                <Route path="/admins" element={<UserList />} />
                <Route path="/banners" element={<Banners />} />
                <Route path="*" element={<Navigate to="/nin-admin" replace />} />
            </Routes>
        </>
    );
};