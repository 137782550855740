import React, { useState } from 'react'

import img_1 from '../../../Assets/Resources/Ponencias/20230325_132952.jpg'
import img_2 from '../../../Assets/Resources/Ponencias/20230325_135528.jpg'
import img_3 from '../../../Assets/Resources/Ponencias/256821981_10159674169468839_3679364138007488514_n.jpg'
import img_4 from '../../../Assets/Resources/Ponencias/PHOTO-2024-02-21-11-00-51.jpg'
import img_5 from '../../../Assets/Resources/Ponencias/img_1.jpeg'
import img_6 from '../../../Assets/Resources/Ponencias/img_2.jpeg'

//Gallery
import { Gallery } from "react-grid-gallery";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'

//MUI
import { Container, Typography } from '@mui/material'

const images = [
  {
    src: img_1,
    original: img_1,
    width: 350,
    height: 174,
  },
  {
    src: img_2,
    original: img_2,
    width: 320,
    height: 212,
  },
  {
    src: img_3,
    original: img_3,
    width: 400,
    height: 212,
  },
  {
    src: img_4,
    original: img_4,
    width: 260,
    height: 320,
  },
  {
    src: img_5,
    original: img_5,
    width: 320,
    height: 183,
  },
  {
    src: img_6,
    original: img_6,
    width: 320,
    height: 212,
  },
]

const Ponencias = () => {

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <Container maxWidth='xl' sx={{ my: 8 }}>
            <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                Ponencias
            </Typography>
            <div>
                <Gallery
                    images={images}
                    onClick={handleClick}
                    enableImageSelection={false}
                />
                {!!currentImage && (
                    <Lightbox
                        mainSrc={currentImage.original}
                        mainSrcThumbnail={currentImage.src}
                        nextSrc={nextImage.original}
                        nextSrcThumbnail={nextImage.src}
                        prevSrc={prevImage.original}
                        prevSrcThumbnail={prevImage.src}
                        onCloseRequest={handleClose}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                    />
                )}
            </div>
        </Container>
  )
}

export default Ponencias