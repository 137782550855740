import React, { useState, useEffect, useContext, useRef } from 'react';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';

import '../blog.css';

import { AdminContext } from '../../../../context/AdminContext';

import BlogCategory from '../BlogCategory';
import CustomToolbar from '../CustomToolbar';
import BlogRowEdition from './BlogRowEdition';
import BlockDialog from '../BlogContent/BlockDialog';
import RowAdditionDialog from '../child/RowAdditionDialog';
import QuillEditDialog from '../BlogContent/QuillEditDialog';
import BasicDateTimePicker from '../BlogContent/BlogDatePicker';
import ImageBlockDialog from '../BlogPost/child/ImageBlockDialog';

import { getBlogURL, getFilesCount } from '../../../../helpers/admin/adminBlogHelper';
import { updateBlogDB, uploadBlogContentImages } from '../../../../requests/admin/blog';
import { basicMsg, simpleConfirmDialog, sweetMsg } from '../../../../helpers/admin/msgHelper';
import { blogEditionChangesValidator, blogFormValidator } from '../../../../validators/admin/AdminBlogValidator';

import {
    BLOG_INIT,
    // BLOG_UPD,
    BLOG_UPD_IMG, BLOG_VIDEO, FORMATS, MODULES
} from '../../../../constants/admin/blog_constants';
import { GOOGLE_DRIVE_CDN } from '../../../../constants/main_constants';

import {
    Box, Container, TextField, Typography,
    Button, Stack, Fab, Grid, Backdrop,
    CircularProgress, IconButton, FormControl,
    FormLabel, RadioGroup, FormControlLabel,
    Radio
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CategoryDialog from '../child/CategoryDialog';

const BlogUpdate = () => {
    const navigate = useNavigate();

    const { data: { blog, blogState, initialState }, setData } = useContext(AdminContext);
    const { blog_id, category_id, imgId, folderId, content } = blogState;
    
    // url de blog
    const { url } = useParams();

    const [blogModel, setBlogModel] = useState(BLOG_INIT);
    const { title, publication_date, author } = blogModel;

    // imagen de portada
    const [mainImg, setMainImg] = useState(BLOG_UPD_IMG);
    const { imgSrc, imgFile, location } = mainImg;

    // categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState('');
    
    // campo Quill Editor
    const [text, setText] = useState('');

    // contenido en filas/columnas
    const [row, setRow] = useState([]);
    
    // contador de filas y columnas (para asignar id's)
    const [rowCounter, setRowCounter] = useState(0);
    const [colCounter, setColCounter] = useState(0);

    // longitud de columnas según el dispositivo
    const [xs, setXS] = useState(12);
    const [md, setMD] = useState(6);

    // mostrar diálogo de edición de bloque
    const [open, setOpen] = useState(false);
    // mostrar diálogo de longitud de bloque
    const [open1, setOpen1] = useState(false);
    
    // archivos para contenido de blog
    const [tmpFiles, setTmpFiles] = useState([]);
    // mostrar diálogo de longitud de bloque de imagen
    const [open2, setOpen2] = useState(false);

    // diálogo para agregar fila
    const [open3, setOpen3] = useState(false);

    // diálogo para editar categorías
    const [open4, setOpen4] = useState(false);

    // archivos a eliminar
    const [oldFiles, setOldFiles] = useState([]);

    // cargando / deshabilitar botón
    const [loading, setLoading] = useState(false);

    //Ref para privilegios
    const rowsRef = useRef([]);
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (blog_id === '0') { // no se ha asignado blog a editar (se accedió mediante url)
            if (!initialState) { // cargó información para admin
                const found = blog.find(e => e.url === url);
                if (found) {
                    setData(e => ({
                        ...e,
                        blogState: {
                            ...e.blogState,
                            ...found
                        }
                    }));
                } else navigate('/nefro-admin/blog');
            }
        }
    }, [initialState]);

    useEffect(() => {
        if (blog_id !== '0') {
            setBlogModel(e => ({ ...e, ...blogState }));
            setMainImg(e => ({
                ...e,
                imgSrc: `${GOOGLE_DRIVE_CDN}${imgId}`,
                imgFile: ''
            }));
            buildContent();
            setSelectedCategory(category_id);
        }
    }, [blogState]);

    // colocar filas y columnas de contenido
    const buildContent = () => {
        let rowNumber = 0;
        let colNumber = -1;
        const _content = [
            {
                row_id: 0,
                id: 'row-0',
                col: [],
            },
            ...content.map(e => {
                rowNumber++;
                return {
                    ...e,
                    row_id: e.id,
                    id: `row-${rowNumber}`,
                    col: e.col.map(u => {
                        colNumber++;
                        return {
                            ...u,
                            col_id: u.id,
                            src: '', file: '',
                            id: `col-${colNumber}`,
                            // el bloque contiene un archivo (imagen/video)
                            // subido a Google Drive
                            cloud_file: ['img', 'video'].includes(u.type) ?
                                true : false
                        };
                    }),
                    // row_id
                };
            })
        ];
        // contadores para próximas filas y columnas
        setRowCounter(rowNumber + 1);
        setColCounter(colNumber + 1);
        setRow(_content);
    };

    // cambios en campos
    const handleOnChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'title') {
            if (value.length <= 200)
                setBlogModel({ ...blogModel, [name]: value });
        } else if (name === 'author') {
            if (value.length <= 120)
                setBlogModel({ ...blogModel, [name]: value });
        }
    };

    // subir portada de blog
    const uploadMainImg = ({ target }) => {
        if (target.files.length > 0) {
            const file = target.files[0];
            if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async (event) => {
                    const src = event.target.result;
                    setMainImg({ ...mainImg,
                        imgSrc: src,
                        imgFile: file,
                        location: 'local'
                    });
                    // se remueve imagen principal
                    setOldFiles([...oldFiles, imgId]);
                };
            }
        }
    };

    // cambios en Quill Editor
    const handleChange = (html) => {
        if (html === '<p><br></p>')
            setText('');
        else
            setText(html);
    };

    // restablecer imagen de portada
    const previousMainImg = () => {
        setMainImg({
            imgSrc: `${GOOGLE_DRIVE_CDN}${imgId}`,
            imgFile: '',
            location: 'google_drive'
        });
        // setOldFiles(oldFiles.filter(e => e !== imgId));
        setOldFiles(e => ([ ...e.filter(u => u !== imgId) ]));
    };

    // guardar bloque
    const addBlock = () => {
        if (text !== '') {
            setOpen1(true);
        } else basicMsg('Ingrese contenido en el recuadro');
    };

    // seleccionar longitud de bloque y guardar
    const insertBlock = () => {
        // reemplazar aquí
        // const item = text.replace('class', 'className');
        let _content = [...row];
        _content[0].col.push({
            item: text,
            src: '', file: '', fileId: '',
            type: 'txt', xs, md,
            id: `col-${colCounter}`,
            col_id: 0 // nuevo bloque
        });
        setRow(_content);
        setColCounter(colCounter + 1);
        setOpen1(false);
        // limpiar campo de Quill
        setText('');
    };

    // limpiar contenido de Quill
    const cleanBlock = () => {
        if (text !== '')
            Swal.fire({
                title: 'Limpiar el contenido del bloque',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed)
                    setText('');
            });
    };

    // subir imagen/video a contenido de blog
    // const uploadFiles = async({ target }) => {
    //     const promise = await loadFiles(target.files);
    //     if (promise) {
    //         const { columns, counter } = promise;
    //         let content = [...row];
    //         content[0].col.push(...columns);
    //         setRow(content);
    //         setColCounter(counter);
    //     }
    // };

    // subir imagen/video para contenido de blog
    const uploadFiles = async({ target }) => {
        if (target.files.length > 0) {
            setOpen2(true);
            setTmpFiles([...target.files]);
        }
    };

    const insertFileBlock = async () => {
        const promise = await loadFiles();
        if (promise) {
            const { columns, counter } = promise;
            let content = [...row];
            content[0].col.push(...columns);
            setRow(content);
            setColCounter(counter);
            setOpen2(false);
        }
    };

    // cargar archivos mediante Promesa
    const loadFiles = async() => {
        if (tmpFiles.length > 0) {
            let counter = colCounter;
            const filePromise = tmpFiles.map(file => {
                return new Promise((resolve, reject) => {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async (event) => {
                            const src = event.target.result;
                            const item = `<img style="vertical-align: bottom" src="${src}" alt="imagen-blog-NIN-Institute">`;
                            resolve({
                                item,
                                src,
                                file,
                                type: 'img',
                                xs, md,
                                id: `col-${counter}`,
                                cloud_file: false // imagen local (recién subido)
                            });
                            counter++;
                        };
                    } else if (file.type === 'video/mp4') {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = (event) => {
                            const src = event.target.result;
                            const item = `<div><h6>Video: ${file.name}</h6><img style="height: 200px; display:block; margin: auto;" src=${BLOG_VIDEO} alt="video-blog-NIN-Institute"></div>`;
                            resolve({
                                item,
                                src,
                                file,
                                type: 'video',
                                xs, md,
                                id: `col-${counter}`,
                                cloud_file: false // video local (recién subido)
                            });
                            counter++;
                        };
                    }
                });
            });
            return {
                columns: await Promise.all(filePromise),
                counter
            }
        };
    };

    const updateBlog = () => {
        if (blogFormValidator({ ...blogModel, selectedCategory, row })) {
            Swal.fire({
                title: '¿Actualizar el blog?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then(async(result) => {
                if (result.isConfirmed) {
                    const rowContent = row.filter((e, i) => i !== 0 && e.col.length > 0);
                    const { files, filesCount, text_preview } = getFilesCount(rowContent, imgFile);

                    let i = 0; // contador de filas
                    let contentModel = []; // contenido dinámico hacia BD
                    const url = getBlogURL(title.trim());

                    if (filesCount > 0) { // archivos recién subidos
                        setLoading(true);
                        const data = new FormData();
                        data.append('folderId', folderId);
                        for (const e of files) {
                            data.append('file', e);
                        };
                        const req = await uploadBlogContentImages(data, 'update');
                        if (req.status) {
                            const { imagesId } = req.resp;

                            if (filesCount === imagesId.length) {
                                let j = 0; // contador de archivos
                                
                                // imagen principal
                                const newMainImg = imgFile !== '' ? imagesId[0] : imgId;
                                // arreglo de id's de archivos
                                const images = imgFile !== '' ?
                                    imagesId.length > 1 ? imagesId.filter((e, i) => i !== 0) : []
                                : imagesId;
                                for (const e of rowContent) {

                                    contentModel = [...contentModel, {
                                        col: []
                                    }];
                                    for (const col of e.col) {
                                        const { item, type, xs, md, fileId } = col;
                                        if (col.type === 'img' && !col.cloud_file) {
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item: `<img src="${GOOGLE_DRIVE_CDN}${images[j]}" alt="imagen-blog-NIN-Institute">`,
                                                type, xs, md,
                                                fileId: images[j]
                                            }];
                                            j++;
                                        } else if (col.type === 'video' && !col.cloud_file) {
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item: `<iframe src="https://drive.google.com/file/d/${images[j]}/preview" title="video-blog-NIN-Institute" width="100%" height="100%"></iframe>`,
                                                type, xs, md,
                                                fileId: images[j]
                                            }];
                                            j++;
                                        } else
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item, type, xs, md, fileId
                                            }];
                                    }
                                    i++;
                                };
                                if (await updateBlogDB({
                                    ...blogModel, blog_id,
                                    url, text_preview,
                                    category_id: selectedCategory,
                                    content: contentModel,
                                    folderId,
                                    imgId: newMainImg,
                                    // imgUploaded: imgFile !== '' ? true : false
                                    deletedFiles: oldFiles
                                }, setLoading, setData))
                                    navigate('/nin-admin/blog');
                            } else {
                                sweetMsg('warning', 'Blog',
                                'No se han subido todas las imágenes');
                                // construir el contenido sin los bloques con imagen/video
                                for (const e of rowContent) {
                                    contentModel = [...contentModel, {
                                        col: []
                                    }];
                                    for (const col of e.col) {
                                        const { item, type, xs, md, fileId } = col;
                                        if (col.type !== 'img' && col.type !== 'video')
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item, type, xs, md, fileId
                                            }];
                                    };
                                    i++;
                                };
                                
                                if(await updateBlogDB({
                                    ...blogModel, blog_id,
                                    url, text_preview,
                                    category_id: selectedCategory,
                                    content: contentModel,
                                    imgId, folderId,
                                    deletedFiles: oldFiles
                                }, setLoading, setData));
                                    navigate('/nin-admin/blog');
                            }
                        }
                    } else {
                        // construir el contenido sin los bloques con imagen/video
                        for (const e of rowContent) {
                            contentModel = [...contentModel, {
                                col: []
                            }];
                            for (const col of e.col) {
                                const { item, type, xs, md, fileId } = col;
                                contentModel[i].col = [...contentModel[i].col, {
                                    item, type, xs, md, fileId
                                }];
                            }
                            i++;
                        };

                        if (await updateBlogDB({
                            ...blogModel, blog_id,
                            url, text_preview,
                            category_id: selectedCategory,
                            content: contentModel,
                            imgId, folderId,
                            deletedFiles: oldFiles
                        }, setLoading, setData))
                            navigate('/nin-admin/blog');
                    }
                }
            });
        }
    };

    // volver a lista de blog
    const goBack = async () => {
        if (blogEditionChangesValidator(blogState, blogModel)) {
            if (await simpleConfirmDialog('No se han guardado los cambios, ¿salir?', 'Sí, salir'))
                navigate('/nin-admin/blog');
        } else navigate('/nin-admin/blog');
    };

    // nueva ventana con vista previa de publicación
    const seeBlogPreview = () => {
        localStorage.setItem('blog_preview', JSON.stringify({
            blog_id, title, publication_date, content: row
        }));
        window.open('/nin-admin/vista-previa-blog', '_blank', 'rel=noopener noreferrer');
    };

    // ir a posición de fila
    const scrollToRow = ({ target }) => {
        setValue(target.value);
        setTimeout(() => {
            rowsRef.current[target.value].scrollIntoView();
        }, 100);
    };

    return (
        <Container maxWidth="xl" sx={{ mb: 5, mt: '90px' }}>
            <Stack direction="row"
                alignItems="flex-start"
                spacing={2} sx={{ pb: 2 }}>
                <IconButton aria-label="go-back"
                    sx={{
                        backgroundColor: '#1976D2', color: 'white', '&:hover': {
                            backgroundColor: 'black'
                        }
                    }}
                    onClick={ goBack }>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h5' paragraph>
                    Actualizar blog
                </Typography>
            </Stack>

            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth
                        sx={{ mb: 2 }}
                        variant='outlined'
                        label="Título del blog"
                        size='small'
                        name='title'
                        inputProps={{ maxLength: 200 }}
                        value={ title }
                        onChange={ handleOnChange }
                    />
                    <TextField fullWidth
                    sx={{ mb: 2 }}
                        variant='outlined'
                        label="Autor del blog"
                        size='small'
                        name='author'
                        inputProps={{ maxLength: 100 }}
                        value={ author }
                        onChange={ handleOnChange }
                    />
                    <BasicDateTimePicker
                        values={ blogModel }
                        setValues={ setBlogModel }
                    />
                    <BlogCategory
                        selectedCategory={ selectedCategory }
                        setSelectedCategory={ setSelectedCategory }
                        setOpen={ setOpen4 }
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <Stack direction="column">
                        <Button variant="outlined" startIcon={<InsertPhotoIcon />}
                            component="label" sx={{ mb: 2 }}>
                            <input hidden accept="image/*"
                                type="file" onChange={ uploadMainImg }
                            />
                            Actualizar portada
                        </Button>
                        {
                            imgFile !== '' &&
                                <Button variant="contained" startIcon={<UndoIcon />}
                                    onClick={ previousMainImg }>
                                    Restablecer imagen
                                </Button>
                        }
                        <img src={ imgSrc } alt='portada-blog' />
                    </Stack>
                </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
                <CustomToolbar />
                <ReactQuill
                    value={ text }
                    name='quill'
                    onChange={ handleChange }
                    modules={ MODULES }
                    formats={ FORMATS }
                />
            </Box>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button variant="contained"
                    onClick={ addBlock }>
                    Guardar
                </Button>
                <Button onClick={ cleanBlock }>
                    Limpiar
                </Button>
                <Button variant="outlined" startIcon={<InsertPhotoIcon />} component="label">
                    <input hidden accept="image/*, video/*"
                        type="file" onChange={ uploadFiles }
                        multiple="multiple" />
                    Agregar imagen/video
                </Button>
            </Stack>

            <Box sx={{ mt: 3 }}>
                <Typography variant='body1'>Preview en HTML</Typography>
                <textarea defaultValue={text} rows={5} style={{ width: '100%' }} disabled></textarea>
            </Box>

            <BlogRowEdition setOpen={ setOpen }
                row={ row }
                setRow={ setRow }
                oldFiles={ oldFiles }
                setOldFiles={ setOldFiles }
                rowsRef={ rowsRef }
            />

            <Button variant='contained'
                onClick={ () => setOpen3(true) }>
                Agregar fila
            </Button>

            <FormControl>
                <FormLabel id="blog-row-radio-buttons-group">
                    Filas de contenido
                </FormLabel>
                <RadioGroup
                    aria-labelledby="blog-row-radio-buttons-group"
                    name="row-radio-buttons-group"
                    value={ value }
                    onChange={ scrollToRow }
                >
                    {
                        row.map(({ row_id }, i) =>
                            <FormControlLabel
                                key={ row_id } value={i}
                                control={<Radio />}
                                label={
                                    `Ir a ${
                                        i === 0 ?
                                            'fila de bloques guardados'
                                        :
                                            `fila ${i}`
                                    }`
                                }
                            />
                        )
                    }
                </RadioGroup>
            </FormControl>

            <QuillEditDialog open={ open }
                setOpen={ setOpen }
                row={ row } setRow={ setRow }
            />

            <BlockDialog open={ open1 }
                setOpen={ setOpen1 }
                xs={ xs } setXS={ setXS }
                md={ md } setMD={ setMD }
                insertBlock={ insertBlock }
            />
            <ImageBlockDialog open={ open2 }
                setOpen={ setOpen2 }
                xs={ xs } setXS={ setXS }
                md={ md } setMD={ setMD }
                insertFileBlock={ insertFileBlock }
            />
            <RowAdditionDialog open={ open3 }
                setOpen={ setOpen3 }
                setRow={ setRow }
                rowCounter={ rowCounter }
                setRowCounter={ setRowCounter }
            />
            <CategoryDialog open={ open4 }
                setOpen={ setOpen4 }
                selectedCategory={ selectedCategory }
                setSelectedCategory={ setSelectedCategory }
            />

            <Fab color="primary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    left: (theme) => theme.spacing(2)
                }}
                disabled={ loading }
                onClick={ updateBlog }>
                <SaveAsIcon />
            </Fab>

            <Fab color="secondary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                disabled={ loading }
                onClick={ seeBlogPreview }>
                <VisibilityIcon />
            </Fab>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ loading }>
                <CircularProgress />
            </Backdrop>
        </Container>
    );
};

export default BlogUpdate;