import React from 'react';

import { BLOCK_LENGTH, NIN_COVER } from '../../../../../constants/admin/blog_constants';

import {
    Button, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, InputLabel, Select,
    MenuItem, Grid, Card, CardMedia
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GOOGLE_DRIVE_CDN } from '../../../../../constants/main_constants';

const ImageBlockDialog = ({ open, setOpen, xs, setXS, md, setMD, insertFileBlock }) => {
    const matches = useMediaQuery('(max-width:600px)');

    const handleDesktopView = ({ target }) => {
        setMD(target.value);
    };

    const handleMobileView = ({ target }) => {
        setXS(target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog
            open={ open }
            onClose={ handleClose }
            aria-labelledby="image-block-dialog-title"
            aria-describedby="image-block-dialog-description"
            PaperProps={{ sx: { width: matches ? '100%' : '30%' } }}>
            <DialogTitle id="image-block-dialog-title">
                Longitud de imagen
            </DialogTitle>
            <DialogContent>
                {/* Longitud de escritorio */}
                <FormControl fullWidth variant="standard"
                    sx={{ mb: 2 }}>
                    <InputLabel id="image-md-select-label">Longitud de imagen en escritorio</InputLabel>
                    <Select
                        labelId="image-md-select-label"
                        id="image-md-select"
                        value={md}
                        label="Longitud de imagen en escritorio"
                        onChange={ handleDesktopView }>
                        {
                            BLOCK_LENGTH.map(e =>
                                <MenuItem key={e}
                                    value={e}>{e}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={md}>
                        <Card>
                            <CardMedia
                                sx={{ height: 150 }}
                                image={`${GOOGLE_DRIVE_CDN}${NIN_COVER}`}
                                title="Portada NIN"
                            />
                        </Card>
                    </Grid>
                </Grid>
                {/* Longitud de móviles */}
                <FormControl fullWidth variant="standard"
                    sx={{ my: 2 }}>
                    <InputLabel id="image-xs-select-label">Longitud de bloimagenque en móviles</InputLabel>
                    <Select
                        labelId="image-xs-select-label"
                        id="image-xs-select"
                        value={xs}
                        label="Longitud de imagen en móviles"
                        onChange={ handleMobileView }>
                        {
                            BLOCK_LENGTH.map(e =>
                                <MenuItem key={e}
                                    value={e}>{e}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={xs}>
                        <Card>
                            <CardMedia
                                sx={{ height: 150 }}
                                image={`${GOOGLE_DRIVE_CDN}${NIN_COVER}`}
                                title="Portada NIN"
                            />
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>Cancelar</Button>
                <Button onClick={ insertFileBlock } autoFocus>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageBlockDialog;