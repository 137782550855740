import React from 'react';
import { Link } from 'react-router-dom';

//CSS
import './Index.css';

//MUI
import { Box, Card, CardContent, CardMedia, Container, Divider, Grid, Typography } from '@mui/material';

//components
import IndexHeader from '../IndexHeader/IndexHeader';

//Images
import logo from '../../Assets/Images/logo_NIN.png'
import img from '../../Assets/Images/1080x920/HomePage.png'
import img_1 from '../../Assets/Images/1080x920/card_1.jpg'
import img_2 from '../../Assets/Images/1080x920/card_2.jpg'
import img_3 from '../../Assets/Images/1440x782/Home page_2.jpg'
import logo_1 from '../../Assets/Images/1080x920/logo_nin.png'

const Index = () => {
    return (
        <>

            <div className='index_d_t'>
                <IndexHeader />

                <Container maxWidth='xl' sx={{ my: 6 }}>
                    <Box textAlign='center' mb={5}>
                        <img src={logo} alt={logo} width='170' />
                        <Divider sx={{ width: '60%', mx: 'auto', my: 3 }} />
                        <Typography variant='body1' textAlign='left'>
                            Es un centro de invertigación, innovación y desarrollo de soluciones especializadas en nutrición, en el que se desarrollan
                            productos nutricionales innovadores y especializados, que permitan mejorar la salud y calid de vida de quien los consume.
                        </Typography>
                    </Box>
                </Container>

                <Container maxWidth='md' sx={{ my: 6 }}>
                    <Grid container spacing={4}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ display: 'flex', mx: 'auto' }}>
                            <Card sx={{ backgroundColor: '#00CE91', width: '100%' }}>
                                <CardMedia
                                    sx={{ height: 250 }}
                                    image={img_1}
                                    title={img_1}
                                />
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Typography variant="body1" sx={{ color: '#fff' }} gutterBottom>
                                        <b>Más de 15 años de experiencia</b> como instituto investigador y desarrollador
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ display: 'flex', mx: 'auto' }}>
                            <Card sx={{ backgroundColor: '#00CE91', width: '100%' }}>
                                <CardMedia
                                    sx={{ height: 250 }}
                                    image={img_2}
                                    title={img_2}
                                />
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Typography variant="body1" sx={{ color: '#fff' }} gutterBottom>
                                        <b>Más de 25 productos</b> desarrollados.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

                {/* <div className='banner_img'>
                    <img src={img_3} alt={img_3} />
                </div> */}

                <div className="banner_img_container" style={{ marginBottom: '150px' }}>
                    <div className='banner_img'>
                        <img src={img_3} alt={img_3} />
                    </div>
                    <div className="logo_container banner-container-logo">
                        <img src={logo_1} alt={logo_1} width='230' />
                    </div>
                </div>

                <Container maxWidth='xl' sx={{ my: 6 }}>
                    <Typography component={Link} to='nin-academy'
                    sx={{ fontSize: '1.8rem', color: '#00CE91', fontWeight: '900', lineHeight: '30px', textDecoration: 'none' }}
                    >
                        Webinars, Capacitaciones, Ponencias y Charlas
                        </Typography>
                    <ul>
                        <li style={{ marginBottom: '12px' }}>
                            Fomentamos el aprendizaje continuo con webinars, capacitaciones especializadas, ponencias de
                            líderes de la industria y charlas inspiradoras, tanto en línea como presenciales.</li>
                        <li>Descubre nuestro contenido especializado en constante evolución.</li>
                    </ul>
                </Container>
            </div>

            <div className='index_mobile'>
                <div className="position-relative">
                    <IndexHeader />

                    <div className="position-absolute image-container">
                        <Box className='card_floating' sx={{ p: 3 }}>
                            <img src={logo} alt={logo} width='140' />
                            <Divider sx={{ width: '45%', mx: 'auto', my: 1.5, borderColor: '#808080' }} />
                            <Typography variant='body2'>
                                Es un centro de invertigación, innovación y desarrollo de soluciones especializadas en nutrición, en el que se desarrollan
                                productos nutricionales innovadores y especializados, que permitan mejorar la salud y calid de vida de quien los consume.
                            </Typography>
                            <div className='cards_index'>
                                <Typography variant='body1' sx={{ fontWeight: 'bold', color: '#00CE91', my: 2 }}>
                                    "ABRAZAMOS LA SALUD Y NUTRICIÓN CON CIENCIA"
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                        <Card sx={{ backgroundColor: '#00CE91', borderRadius: '45px' }}>
                                            <CardMedia
                                                sx={{ height: 160 }}
                                                image={img_1}
                                                title={img_1}
                                            />
                                            <CardContent sx={{ p: '9px', '&:last-child': { pb: 1 } }}>
                                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                                    <b>Más de 15 años de experiencia</b> como instituto investigador y desarrollador
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                        <Card sx={{ backgroundColor: '#00CE91', borderRadius: '45px' }}>
                                            <CardMedia
                                                sx={{ height: 160 }}
                                                image={img_2}
                                                title={img_2}
                                            />
                                            <CardContent sx={{ p: '9px' }}>
                                                <Typography variant="body2" sx={{ color: '#fff' }}>
                                                    <b>Más de 25 productos</b> desarrollados.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                    </div>
                </div>

                <div className="position-relative-logo" style={{ marginBottom: '20px' }}>
                    <img src={img} alt={img} width='100%' />
                    <div className="position-absolute-logo image-container-logo">
                        <img src={logo_1} alt={logo_1} width='40%' />
                    </div>
                </div>

                <Container maxWidth='xl' className='cards'>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#00CE91', my: 2 }} textAlign='center'>
                        "ABRAZAMOS LA SALUD Y NUTRICIÓN CON CIENCIA"
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card sx={{ backgroundColor: '#00CE91', borderRadius: '45px', width: '100%', textAlign: 'center' }}>
                                <CardMedia
                                    sx={{ height: 220 }}
                                    image={img_1}
                                    title={img_1}
                                />
                                <CardContent>
                                    <Typography variant="body1" sx={{ color: '#fff' }} gutterBottom>
                                        <b>Más de 15 años de experiencia</b> como instituto investigador y desarrollador
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{ backgroundColor: '#00CE91', borderRadius: '45px', width: '100%', textAlign: 'center' }}>
                                <CardMedia
                                    sx={{ height: 220 }}
                                    image={img_2}
                                    title={img_2}
                                />
                                <CardContent>
                                    <Typography variant="body1" sx={{ color: '#fff' }} gutterBottom>
                                        <b>Más de 25 productos</b> desarrollados.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

                <Container maxWidth='xl' sx={{ my: 5 }}>
                    <Typography component={Link} to='/nin-academy'
                        sx={{ fontSize: '1.8rem', color: '#00CE91', fontWeight: '900', lineHeight: '30px', textDecoration: 'none' }}
                    >
                        Webinars, Capacitaciones, Ponencias y Charlas
                    </Typography>
                    <ul>
                        <li style={{ marginBottom: '12px' }}>
                            Fomentamos el aprendizaje continuo con webinars, capacitaciones especializadas, ponencias de
                            líderes de la industria y charlas inspiradoras, tanto en línea como presenciales.</li>
                        <li>Descubre nuestro contenido especializado en constante evolución.</li>
                    </ul>
                </Container>
            </div>
        </>
    )
}

export default Index