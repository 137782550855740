import React, { useEffect } from 'react';

//MUI
import { createTheme, ThemeProvider } from '@mui/material';

//Router dom
import { useLocation } from 'react-router-dom';

//Context
import MainProvider from './context/MainContext';
import AppUIContextProvider from './context/UIContext';
import AdminContextProvider from './context/AdminContext';
import RouterContextProvider from './context/RouterContext';

//Components
import AppRoute from './router/AppRoute';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1536,
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
});

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const App = () => {

  useScrollToTop();

  return (
    <ThemeProvider theme={theme}>
      <AppUIContextProvider>
        <RouterContextProvider>
          <AdminContextProvider>
            <MainProvider>
              <div id='app'>
                <AppRoute />
              </div>
            </MainProvider>
          </AdminContextProvider>
        </RouterContextProvider>
      </AppUIContextProvider>
    </ThemeProvider>
  );
};

export default App;