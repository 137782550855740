import React from 'react';

//MUI
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const FilterCategory = ({ handleChangeChecked, categoryObj }) => {
    const { checked, category, category_id } = categoryObj;

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        size='small'
                        checked={checked}
                        onChange={
                            () => handleChangeChecked(category_id)
                        }
                    />
                }
                label={ category }
            />
        </FormGroup>
    );
};

export default FilterCategory;