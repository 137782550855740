import React, { useState } from 'react'


//MUI
import { Avatar, Box, Link, Typography } from '@mui/material'

const Experts = ({ item }) => {

    const [showMore, setShowMore] = useState(false)

    return (
        <Box
            sx={{
                display: { xs: 'flex', sm: 'flex', md: 'block', lg: 'block', xl: 'block' },
                textAlign: { xs: 'left', sm: 'left', md: 'center', lg: 'center', xl: 'center' }
            }}
            justifyContent='flex-start' gap='20px'
        >
            <Avatar
                alt={item.img}
                src={item.img}
                sx={{ width: 160, height: 160, mx: 'auto', mb: 1.5, objectFit: 'cover', bgcolor: '#ECECEC' }}
            >

            </Avatar>
            <Box>
                <Typography variant='body1' sx={{ color: '#283A49', fontWeight: 'bold' }}>
                    {item.name}
                </Typography>
                <Typography variant='body1' paragraph>
                    {item.workstation}
                </Typography>
                {
                    showMore
                        ?
                        <Typography variant='body2'>
                            {item.description}
                        </Typography>
                        :
                        <Typography variant='body2'>
                            {item.description.substring(0, 130)}...
                        </Typography>
                }
                <Link onClick={() => setShowMore(!showMore)} underline="none" sx={{ color: '#666666', fontWeight: 'bold', fontSize: '13px', cursor: 'pointer' }}>
                    {showMore ? 'Ver menos' : ' Ver más +'}
                </Link>
            </Box>
        </Box>

    )
}

export default Experts