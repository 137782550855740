import React, { useEffect, useState, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { AdminContext } from '../../../../context/AdminContext';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/main_constants';

import {
    Card, CardMedia, IconButton, ImageList, CardActions,
    useMediaQuery
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const BannerImageList = ({ type, setBanner, deletedImages, setDeletedImages }) => {
    const { data: { banners: { index } }, setData } = useContext(AdminContext);

    const matches = useMediaQuery('(max-width: 768.5px)');

    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(index[type]);
    }, [type, index]);
    
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // setImages(items);
        setData(e => ({
            ...e,
            banners: {
                ...e.banners,
                index: {
                    ...e.banners.index,
                    [type]: items
                }
            }
        }));
    };

    // remueve imágenes guardadas en Google Drive de banner
    const removeImg = banner_id => () => {
        const banner = images.find(e => e.banner_id === banner_id);
        if (banner) {
            setDeletedImages({
                ...deletedImages,
                [type]: [...deletedImages[type], {
                    banner_id, imgId: banner.imgId
                }]
            });
            // setImages(e => e.filter(u => u.imgId !== banner.imgId));
            setData(e => ({
                ...e,
                banners: {
                    ...e.banners,
                    index: {
                        ...e.banners.index,
                        [type]: e.banners.index[type].filter(u => u.banner_id !== banner_id)
                    }
                }
            }));
        }
    };

    return (
        <DragDropContext onDragEnd={ handleOnDragEnd }>
            <Droppable droppableId="desktop">
                {(provided) => (
                    <ImageList gap={20} cols={ matches ? 2 : 3 }
                        { ...provided.droppableProps }
                        ref={ provided.innerRef }>
                        {
                            images.length > 0 &&
                            images.map(({ banner_id, imgId }, index) => {
                                return (
                                    <Draggable key={banner_id}
                                        draggableId={banner_id} index={index}>
                                        {(provided) => (
                                            <Card key={index}
                                                ref={ provided.innerRef }
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                variant="elevation" sx={{ mb: 1 }}>
                                                <CardMedia
                                                    component="img"
                                                    alt={ 'banner-nin-institute' }
                                                    height="140"
                                                    image={ `${GOOGLE_DRIVE_CDN}${imgId} `}
                                                />
                                                <CardActions>
                                                    <IconButton
                                                        onClick={ removeImg(banner_id) }
                                                    >
                                                        <RemoveCircleIcon />
                                                    </IconButton>
                                                </CardActions>
                                            </Card>
                                        )}
                                    </Draggable>
                                );
                            })
                        }
                        { provided.placeholder }
                    </ImageList>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default BannerImageList;