import React from 'react'

//MUI
import { Container, Typography } from '@mui/material'

const PoliticaPrivacidad = () => {
    return (
        <Container maxWidth='xl' sx={{ my: 8 }}>
            <Typography variant='body1' sx={{ color: '#004878', fontWeight: 'bold', fontSize: '30px' }} paragraph>Aviso de privacidad</Typography>
            <Typography variant='body1' paragraph>
                NUTRICIÓN NIN, S.A. DE C.V. con domicilio en la calle Córdoba 2609, de la Colonia Providencia 3ra Sección en
                Guadalajara, Jalisco, C.P. 44630, Tel. +52 (33) 3825-6149, con un horario de atención de 09:00 a 17:00 hrs., es el
                responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
            </Typography>
            <Typography variant='body1' paragraph>
                Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias
                para el servicio que solicita: para el correcto tratamiento y otorgamiento de la prestación de los servicios y correcto
                y completo conocimiento de los productos que nos solicita, notificarle sobre nuevos servicios o productos, comunicarle
                sobre cambios en los mismos, elaborar estudios y programas que pueden ser necesarios para el otorgamiento de los
                servicios y correcto funcionamiento de los productos que nos solicita, así como realizar evaluaciones periódicas de
                nuestros servicios a efecto de mejorar la calidad de los mismos, evaluar la calidad del servicio que brindamos y en
                general, para dar cumplimiento a las obligaciones que hemos contraído con Usted.
            </Typography>
            <Typography variant='body1' paragraph>
                Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales
                de identificación y contacto, entre ellos, los datos siguientes:
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Datos Sensibles</b>: Además de los datos personales mencionados anteriormente, para las finalidades en el presente aviso de
                privacidad utilizaremos los siguientes datos personas considerados como sensibles, que requieren de especial protección: Datos
                sobre Salud consernientes a la información relacionada con la valoración, preservación, cuidado, mejoramiento y recuperación
                de su estado de salud físico o mental, presente, pasado o futuro.
            </Typography>
            <Typography variant='body1' paragraph>
                De igual manera, se recopilarán los datos siguientes:
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Cookies</b>: Son un archivo de datos que se almacena en el disco duro del equipo de cómputo o del dispositivo de comunicaciones
                electrónicas de un usuario al navegar en un sitio de Internet específico, el cual permite intercambiar información de estado
                entre dicho sitio y el navegador del usuario. La información de estado puede revelar medios de identificación de sesión, autenticación
                o preferencias del usuario, así como cualquier otro dato almacenado por el navegador respecto al sitio de Internet.
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Web beacons</b>: Son una imagen visible u oculta insertada dentro de un sitio web o correo electrónico, que se utiliza para monitorear
                el comportamiento del usuario en estos medios. A través de éstos se puede obtener información como la dirección IP de origen, navegador
                utilizado, sistema operativo, momento en que se accedió a la página, y en el caso del correo electrónico, la asociación de los datos
                anteriores con el destinatario.
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Listado de exclusión</b>: Es una base de datos que tiene por objeto registrar, de manera gratuita, a aquéllos titulares que no quieran que
                su información sea tratada por el responsable para ciertas finalidades, con el objeto de que el responsable evite dichos tratamientos.
            </Typography>
            <Typography variant='body1' paragraph sx={{ textDecoration: 'underline' }}>
                Derechos ARCO
            </Typography>
            <Typography variant='body1' paragraph>
                Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos
                (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta
                o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo
                utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus
                datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
            </Typography>
            <Typography variant='body1' paragraph>
                Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva en nuestro Departamento de
                Protección de Datos Personales, ubicado en la calle Córdoba 2609, de la Colonia Providencia 3ra Sección en
                Guadalajara, Jalisco, C.P. 44630, Tel. +52 (33) 3825-6149, con un horario de atención de 09:00 a 17:00 hrs o vía
                correo electrónico a <a href='mailto:nin-institute@nin.com.mx'>nin-institute@nin.com.mx</a>, el cual solicitamos confirme
                vía telefónica para garantizar su correcta recepción.
            </Typography>
            <Typography variant='body1' paragraph>
                Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es
                importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que
                es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que
                para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o
                la conclusión de su relación con nosotros.
            </Typography>
            <Typography variant='body1' paragraph>
                Para revocar su consentimiento deberá presentar su solicitud respectiva en nuestro Departamento de Protección de Datos Personales, ubicado
                en la calle Córdoba 2609, de la Colonia Providencia 3ra Sección en Guadalajara, Jalisco, C.P. 44630, Tel. +52 (33) 3825-6149, con un
                horario de atención de 09:00 a 17:00 hrs o vía correo electrónico a <a href='mailto:nin-institute@nin.com.mx'>nin-institute@nin.com.mx</a>, el
                cual solicitamos confirme vía telefónica para garantizar su correcta recepción.
            </Typography>
            <Typography variant='body1' paragraph>
                Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:
            </Typography>
            <Typography variant='body1' paragraph>
                I.- Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor, con
                la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para
                mayor información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.
            </Typography>
            <Typography variant='body1' paragraph>
                II.- Su registro en el Listado de Exclusión Ordil, a fin de que sus datos personales no sean tratados para fines mercadotécnicos, publicitarios
                o de prospección comercial por nuestra parte. Para mayor información llamar al número telefónico +52 (33) 3825-6149 o vía correo electrónico
                a <a href='mailto:nin-institute@nin.com.mx'>nin-institute@nin.com.mx</a>, o bien, consultar nuestra página de
                Internet <a href='https://nininstitute.com/' target='_blank' rel="noopener noreferrer">nininstitute.com</a>
            </Typography>
            <Typography variant='body1' paragraph>
                El uso de tecnologías de rastreo en nuestro portal de Internet.
            </Typography>
            <Typography variant='body1' paragraph>
                Le informamos que en nuestra página de Internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales es posible
                monitorear su comportamiento como usuario de Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
            </Typography>
            <Typography variant='body1' paragraph>
                Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Medios</b>: Si subes imágenes a la web deberías evitar subir imágenes con datos de ubicación (GPS EXIF) incluidos. Los visitantes de la web pueden
                descargar y extraer cualquier dato de localización de las imágenes de la web.
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Cookies</b>: Si dejas un comentario en nuestro sitio puedes elegir guardar tu nombre, dirección de correo electrónico y web en cookies. Esto es
                para tu comodidad, para que no tengas que volver a rellenar tus datos cuando dejes otro comentario. Estas cookies tendrán una duración de
                un año. Si tienes una cuenta y te conectas a este sitio, instalaremos una cookie temporal para determinar si tu navegador acepta cookies. Esta
                cookie no contiene datos personales y se elimina al cerrar el navegador.Cuando inicias sesión, también instalaremos varias cookies para guardar
                tu información de inicio de sesión y tus opciones de visualización de pantalla. Las cookies de inicio de sesión duran dos días, y las cookies
                de opciones de pantalla duran un año. Si seleccionas “Recordarme”, tu inicio de sesión perdurará durante dos semanas. Si sales de tu
                cuenta, las cookies de inicio de sesión se eliminarán.Si editas o publicas un artículo se guardará una cookie adicional en tu
                navegador. Esta cookie no incluye datos personales y simplemente indica el ID del artículo que acabas de editar. Caduca después de 1 día.
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Contenido incrustado de otros sitios web</b>: Los artículos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El
                contenido incrustado de otras web se comporta exactamente de la misma manera que si el visitante hubiera visitado la otra web.Estas web pueden
                recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido
                incrustado, incluido el seguimiento de tu interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.
            </Typography>
            <Typography variant='body1' paragraph>
                <b>Uso de Tecnologías Computacionales</b>: Le informamos que para las finalidades del presente aviso de privacidad, en la prestación de los
                servicios, empleamos cuando es posible, y durante diferentes pasos del proceso, la asistencia de diversas tecnologías computacionales que
                incluyen inteligencia artificial, incluido el deep learning (aprendizaje profundo), y/o visión computacional, entre otros; así mismo, le
                informamos que procesamos de manera local o en servidores externos (en la nube), cierta información personal mencionada anteriormente.
            </Typography>
            <Typography variant='body1' paragraph>
                Estas tecnologías podrán deshabilitarse presentando su solicitud respectiva en nuestro Departamento de Protección de Datos Personales, ubicado
                en la calle Córdoba 2609, de la Colonia Providencia 3ra Sección en Guadalajara, Jalisco, C.P. 44630, Tel. +52 (33) 3825-6149, con un horario
                de atención de 09:00 a 17:00 hrs o vía correo electrónico a <a href='mailto:nin-institute@nin.com.mx'>nin-institute@nin.com.mx</a>, el cual
                solicitamos confirme vía telefónica para garantizar su correcta recepción.
            </Typography>
            <Typography variant='body1' paragraph>
                El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de
                nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro
                modelo de negocio, o por otras causas.
            </Typography>
            <Typography variant='body1' paragraph>
                Queda prohibida la transferencia a un tercero de los datos que nos proporciona y nos obligamos a guardar confidencialidad respecto de
                estos aún después de que finalice la relación contractual que en su momento se celebre con usted.
            </Typography>
            <Typography variant='body1' paragraph>
                <u>IMPORTANTE</u>: El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso
                de privacidad es el siguiente: se establecerá el nuevo aviso de privacidad en la página de
                internet <a href='https://nininstitute.com/' target='_blank' rel="noopener noreferrer">nininstitute.com</a>, de igual manera, se le notificará dentro de los
                5 cinco días a que se efectué cualquier cambio al correo electrónico que nos fue proporcionado por Usted.
            </Typography>
            <Typography variant='body1' paragraph>
                Fecha de última actualización: 1º de Enero del 2020.
            </Typography>
        </Container>
    )
}

export default PoliticaPrivacidad