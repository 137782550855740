export const ACTIVE_ADMIN_DEFAULT = {
    admin_id: '',
    email: '',
    username: '',
    role: '',
    // privileges: {
    //     products: false,
    //     coupons: false,
    //     clients: false,
    //     orders: false,
    //     admins: false
    // }
};

export const ADMIN_FIELDS = {
    username: '',
    email: '',
    password: '',
    password_c: '',
    role: ''
};

export const ADMIN_ERROR_FIELDS = {
    username_err: false,
    email_err: false,
    pwd1_err: false,
    pwd2_err: false,
    role_err: false
};