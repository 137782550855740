import { protectedReq } from "../../helpers/request/requestHelper";

// cargar información de sitio para admin
// Blog / Banners / Usuarios
export const loadWebsiteData = async (setData) => {
    try {
        const resp = await protectedReq('admin/load-data');
        const body = await resp.json();
        if (body.status) {
            const { resp } = body;
            setData(e => ({
                ...e, ...resp,
                initialState: false // petición realizada
                // buscar sesión de usuario (ver AdminContext.js)
            }));
        } else
            setData(e => ({
                ...e, initialState: false
            }));
    } catch (err) {
        setData(e => ({
            ...e, initialState: false
        }));
    };
};