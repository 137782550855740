import { createContext, useState } from "react";

export const MainContext = createContext();
const { Provider } = MainContext;

const MainProvider = ({ children }) => {
    const [websiteData, setWebsiteData] = useState({
        banners: {
            index: {
                desktop: [],
                tablet: [],
                mobile: []
            }
        }
    });

    return (
        <Provider value={{ websiteData, setWebsiteData }}>
            { children }
        </Provider>
    );
};

export default MainProvider;