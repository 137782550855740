import React from 'react'

import './Desarrollo.css'

//MUI
import { Box, Container, Typography } from '@mui/material'

const Desarrollo = () => {
    return (
        <Container maxWidth='xl' sx={{ my: 6 }}>
            <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} paragraph>
                Nuestros Estándares de Calidad
            </Typography>
            <Box mb={4}>
            <div className="video_responsive">
                <iframe
                    src="https://www.youtube.com/embed/MbNkIlg7fjU?si=D2yMQP3hgks0YTqI"
                    title="nin_desarrollo"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                >
                </iframe>
                </div>
            </Box>
            <Typography variant='body1' paragraph>
                En <b>NIN INSTITUTE</b> ®, nos comprometemos a mantener los más altos estándares de calidad en todas nuestras operaciones. Para garantizar
                la seguridad y eficacia de nuestros productos, trabajamos en estricto cumplimiento con la Norma Oficial Mexicana NOM 251, que establece los
                lineamientos para la fabricación y comercialización de productos alimenticios y bebidas no alcohólicas.
            </Typography>
            <Typography variant='body1' paragraph>
                Para asegurar la calidad y la inocuidad de nuestros productos, llevamos a cabo evaluaciones microbiológicas exhaustivas tanto en nuestro
                laboratorio interno como a través de colaboraciones con laboratorios externos independientes. Estas evaluaciones rigurosas nos permiten
                mantener un control estricto sobre la calidad de nuestros productos, garantizando que cumplan con los más altos estándares de seguridad y salud.
            </Typography>
            <Typography variant='body1'>
                Nuestra dedicación a la excelencia y a la integridad en todas las etapas de producción refleja nuestro compromiso con la satisfacción y la
                confianza de nuestros clientes.
            </Typography>
        </Container>
    )
}

export default Desarrollo