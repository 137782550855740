import React from 'react'

import './Products.css'

import img_desktop from '../../Assets/Images/1440x782/Productos.jpg'
import img_tablet from '../../Assets/Images/1600x900/Productos.jpg'
import img_mobile from '../../Assets/Images/1080x920/Productos.jpg'
import logo_nin from '../../Assets/Images/1440x782/logo_nin.png'
import logo_zeronovo from '../../Assets/Images/1440x782/logo_zeronovo.png'
import logo_vitanovo from '../../Assets/Images/1440x782/logo_vitanovo.png'

//MUI
import { Box, Container, Grid, IconButton, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const Products = () => {
    return (
        <>
            <div className="position-relative-products">
                <Box>
                    <div className='banner-products-desktop'>
                        <img src={img_desktop} alt={img_desktop} width='100%' />
                    </div>

                    <div className='banner-products-tablet'>
                        <img src={img_tablet} alt={img_tablet} width='100%' />
                    </div>

                    <div className='banner-products-mobile'>
                        <img src={img_mobile} alt={img_mobile} width='100%' />
                    </div>
                </Box>

                <div className="position-absolute-products image-container-products">
                    <div className='card_floating-products'></div>
                </div>
            </div>

            <Container maxWidth='xl' sx={{ mb: 6, mt: 2 }} id='prod_page'>
                <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                    Innovación y compromiso para la salud
                </Typography>
                <Typography variant='body1' paragraph>
                    Cada creación es una fusión de innovación científica y cuidado, diseñada para nutrir y fortalecer.
                </Typography>
                <Typography variant='body1' sx={{ mb: 5 }}>
                    Explora todas nuestras líneas de productos diseñadas para potenciar tu bienestar. Desde soluciones nutricionales hasta
                    productos para el cuidado personal, descubre cómo abrazamos la salud en cada categoría.
                </Typography>
                <Grid container spacing={6}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6} sx={{ mx: 'auto', textAlign: 'center' }}>
                        <img src={logo_nin} alt={logo_nin} width='100%' />
                        <Typography variant='body1' sx={{ my: 1 }}>
                            Nutrición renal. Bienestar especializado.
                        </Typography>
                        <IconButton LinkComponent='a' href='https://nin.com.mx/' target='_blank'>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6} sx={{ mx: 'auto', textAlign: 'center' }}>
                        <img src={logo_zeronovo} alt={logo_zeronovo} width='100%' />
                        <Typography variant='body1' sx={{ my: 1 }}>
                            Productos nutricionales de alta calidad para un estilo de vida saludable.
                        </Typography>
                        <IconButton LinkComponent='a' href='https://zeronovo.mx/' target='_blank'>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={6} sx={{ mx: 'auto', textAlign: 'center' }}>
                        <img src={logo_vitanovo} alt={logo_vitanovo} width='100%' />
                        <Typography variant='body1' sx={{ my: 1 }}>
                            Bienestar Renal.
                        </Typography>
                        <IconButton LinkComponent='a' href='https://vitanovo.mx/' target='_blank'>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Products