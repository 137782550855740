import React, { useState, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { AdminContext } from '../../../../context/AdminContext';

import BannerImageList from './BannerImageList';

import { updateBanners, uploadBannerImages } from '../../../../requests/admin/banner';

import {
    Button, Card, CardActions, CardMedia,
    Container, DialogActions, IconButton,
    ImageList, ImageListItem, useMediaQuery
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const BannerContainer = ({ banner, setBanner, deletedImages, setDeletedImages }) => {
    const { type, images } = banner;
    
    const matches = useMediaQuery('(max-width: 768.5px)');

    const { data: { banners: { index } }, setData } = useContext(AdminContext);
    
    const [loading, setLoading] = useState(false);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setBanner(e => ({
            ...e,
            images: items
        }));
    };
    
    const loadedImages = ({ target }) => {
        let count = images.length; // total de imágenes
        let exceeded = false; // se alcanzó el límite?
        if (count >= 20) {
            alert('No puede agregar más imágenes');
            return;
        }
        if (target.files.length > 0) {
            let target_files = [];
            [...target.files].forEach(element => {
                if (count >= 20) exceeded = true;
                else {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(element.type)) {
                        // target_files = [...target_files, {
                        //     img: element, url: '', expanded: true }
                        // ];
                        target_files = [...target_files, element];
                        count++;
                    }
                }
            });
            setBanner(e => ({
                ...e,
                images: [...e.images, ...target_files]
            }));
            if (exceeded) alert('Se alcanzó el límite de imágenes');
        }
    };
    
    // remueve imágenes recién subidas del dispositivo
    const removeImg = name => () => {
        setBanner(e => ({
            ...e,
            images: e.images.filter(i => (i.name !== name))
        }));
    };

    // guardar imágenes de banners en Google Drive
    const save = async() => {
        setLoading(true);
        
        if (images.length <= 0)
            await updateBanners(type, index[type], deletedImages[type], setData);
        else {
            await uploadBannerImages(
                'index', type, images,
                index[type],
                deletedImages[type], setDeletedImages,
                setData, setBanner
            );
        }
        setLoading(false);
    };

    return (
        <Container maxWidth='xl'>
            
            <BannerImageList
                type={ type }
                setBanner={ setBanner }
                deletedImages={ deletedImages }
                setDeletedImages={ setDeletedImages }
            />

            <DragDropContext onDragEnd={ handleOnDragEnd }>
                <Droppable droppableId="file">
                    {(provided) => (
                        <ImageList gap={20} cols={ matches ? 1 : 3 }
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }>
                            {
                                images.length > 0 &&
                                images.map((img, i) => {
                                    return (
                                        <Draggable key={ img.name }
                                            draggableId={ img.name }
                                            index={i}>
                                            {(provided) => (
                                                <Card key={i}
                                                    ref={ provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }>
                                                    <CardMedia
                                                        component="img"
                                                        alt={ 'banner-nin-institute' }
                                                        height="140"
                                                        image={ URL.createObjectURL(img) }
                                                    />
                                                    <CardActions>
                                                        <IconButton
                                                            onClick={ removeImg(img.name) }
                                                        >
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </CardActions>
                                                </Card>
                                            )}
                                        </Draggable>
                                    );
                                })
                            }
                            { provided.placeholder }
                            <ImageListItem sx={{ height: '250px !important' }} className='uploader'>
                                <Button
                                    component="label"
                                    role={ undefined }
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CameraAltIcon />}
                                >
                                    Subir imágenes
                                    <VisuallyHiddenInput
                                        accept="image/*"
                                        type="file"
                                        multiple
                                        name="images"
                                        onChange={ loadedImages }
                                    />
                                </Button>
                            </ImageListItem>
                            
                        </ImageList>
                    )}
                </Droppable>
            </DragDropContext>

            <DialogActions>
                {
                    loading ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                        >
                            Guardando
                        </LoadingButton>
                    :
                        <Button onClick={ save }>Guardar cambios</Button>
                }
            </DialogActions>
        </Container>
    );
};

export default BannerContainer;