import { sweetMsg } from "../../helpers/admin/msgHelper";
import { protectedReq } from "../../helpers/request/requestHelper";

// registro de administrador
export const saveAdminUser = async (values, setData) => {
    try {
        const { password_c, ...model } = values;
        const resp = await protectedReq('admin-user/save', model, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { password, ...user } = model;
            const { resp: { user_id } } = body;
            setData(e => ({
                ...e, adminUsers:
                [...e.adminUsers, { ...user, user_id }]
            }));
            sweetMsg('success', 'Éxito',
            'Nuevo administrador registrado');
        } else {
            if (body.msg === 'miss')
                sweetMsg('info', 'Nuevo admin',
                'Faltan datos del administrador');
            else if (body.msg === 'email-exists')
                sweetMsg('info', 'Admin encontrado',
                'Ya existe un usuario con ese correo electrónico');
            else if (body.msg === 'user-not-saved')
                sweetMsg('warning', 'Nuevo admin',
                'No se pudo guardar el usuario');
            else if (body.msg === 'admin-not-saved')
                sweetMsg('warning', 'Nuevo usuario',
                'No se pudo registrar el usuario como administrador');
            else if (body.msg === 'server-err')
                sweetMsg('error', 'Nuevo admin',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else sweetMsg('warning', 'Nuevo admin',
                'Inténtelo de nuevo, por favor');
        }
        return true;
    } catch (err) {
        sweetMsg('error', 'Incidente',
        'Recargue la página');
        return false;
    };
};

// actualizar administrador
export const updateAdminUser = async (values, setData) => {
    try {
        const { password_c, ...model } = values;
        const resp = await protectedReq('admin-user/update', model, 'PUT');
        const body = await resp.json();
        if (body.status) {
            const { user_id, password, ...user } = model;
            setData(e => ({
                ...e, adminUsers:
                e.adminUsers.map(item => item.user_id === user_id ?
                    { ...item, ...user } : item
                )
            }));
            sweetMsg('success', 'Éxito',
            'Administrador actualizado');
        } else {
            if (body.msg === 'miss')
                sweetMsg('info', 'Admin',
                'Faltan datos del administrador');
            else if (body.msg === 'user-not-upd')
                sweetMsg('warning', 'Admin',
                'Usuario administrador no actualizado');
            else if (body.msg === 'admin-not-upd')
                sweetMsg('warning', 'Admin',
                'Rol de administrador no actualizado');
            else if (body.msg === 'server-err')
                sweetMsg('error', 'Admin',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else sweetMsg('warning', 'Admin',
                'Inténtelo de nuevo, por favor');
        }
        return true;
    } catch (err) {
        sweetMsg('error', 'Admin', 'Recargue la página');
        return false;
    };
};

// eliminar administrador
export const deleteAdminUser = async (values, setData) => {
    try {
        const { user_id } = values;
        const resp = await protectedReq('admin-user/delete', { user_id }, 'DELETE');
        const body = await resp.json();
        if (body.status) {
            setData(e => ({
                ...e, adminUsers:
                e.adminUsers.filter(item => item.user_id !== user_id)
            }));
            sweetMsg('success', 'Éxito',
            'Administrador eliminado');
        } else {
            if (body.msg === 'miss')
                sweetMsg('warning', 'Admin',
                'No se identifica administrador a eliminar');
            else if (body.msg === 'sql-err')
                sweetMsg('warning', 'Admin',
                'Incidente al tratar de eliminar administrador');
            else if (body.msg === 'admin-not-deleted')
                sweetMsg('warning', 'Admin',
                'El usuario ya no es administrador, sin embargo, no se ha eliminado el usuario');
            else if (body.msg === 'user-not-deleted')
                sweetMsg('warning', 'Admin',
                'No se ha eliminado el usuario');
            else if (body.msg === 'server-err')
                sweetMsg('error', 'Admin',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else sweetMsg('warning', 'Admin',
                'Inténtelo de nuevo, por favor');
        }
        return true;
    } catch (err) {
        sweetMsg('error', 'Admin',
        'Recargue la página');
        return false;
    };
};