import { createContext, useContext, useState } from "react";

import { UIContext } from "./UIContext";
import { checkIfAdmin } from "../helpers/admin/adminHelper";

export const RouterContext = createContext();
const { Provider } = RouterContext;

const USER_DEFAULT = {
    email: '', username: '',
    admin_id: '', role: ''
};

const RouterContextProvider = ({ children }) => {
    // contexto padre
    const { stopLoading, setRedirectPage, handleMainSnackbar } = useContext(UIContext);

    const [item, setItem] = useState(USER_DEFAULT);
    
    // showSnackbar indica si inició sesión por registro/logueo
    // o sólo se recuperó la sesión
    const userLogin = (body, showSnackbar) => {
        const { token, ...user } = body;
        localStorage.setItem('token', token);
        localStorage.setItem('token-date', new Date().getTime());
        stopLoading(
            checkIfAdmin(user.admin_id) ? 'admin' : 'user'
        );
        setItem(e => ({ ...e, ...user }));
        if (showSnackbar)
            handleMainSnackbar(true, 'success', 'Inició sesión');
    };

    const userLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('token-date');
        setRedirectPage('logout');
        setItem(e => ({ ...e, ...USER_DEFAULT }));
        handleMainSnackbar(true, 'info', 'Cerró sesión');
    };

    return (
        <Provider value={{ item, setItem, userLogin, userLogout }}>
            { children }
        </Provider>
    );
};

export default RouterContextProvider;