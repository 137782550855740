import React from 'react';

// import { parseISO } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const BasicDateTimePicker = ({ values, setValues }) => {
    const { publication_date } = values;
    
    return (
        <LocalizationProvider dateAdapter={ AdapterDateFns }>
            <DateTimePicker
                label="Fecha de publicación"
                value={ publication_date }
                onChange={(newValue) => {
                    setValues({ ...values, publication_date: newValue });
                }}
                slotProps={{ textField: { fullWidth: true, size: 'small' } }}
            />
        </LocalizationProvider>
    );
};

export default BasicDateTimePicker;