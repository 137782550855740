import React, { useState, useContext } from 'react';

import { AdminContext } from '../../../context/AdminContext';

//CSS
import './banner.css';
import BannerCard from './BannerCard';
import BannersDialog from './BannersDialog';

//MUI
import {
    Container, Grid, Typography,
} from '@mui/material';

const Banners = () => {
    const { data: { banners } } = useContext(AdminContext);
    const { index: { desktop, tablet, mobile } } = banners;

    const [anchorEl, setAnchorEl] = useState({
        anchorEl1: null,
        anchorEl3: null,
        anchorEl2: null
    });
    const { anchorEl1, anchorEl2, anchorEl3 } = anchorEl;
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);

    // imágenes de banner
    const [banner, setBanner] = useState({
        type: 'desktop', // vista de banner
        images: [] // nuevas imágenes
    });

    // imágenes removidas
    const [deletedImages, setDeletedImages] = useState({
        desktop: [],
        tablet: [],
        mobile: []
    });

    const handleClick = (type, anchor) => (event) => {
        setBanner({ ...banner, type });
        setAnchorEl(({ ...anchorEl, [anchor]: event.currentTarget }));
    };
    const handleClose = anchor => () => {
        setAnchorEl((e) => ({ ...e, [anchor]: null }));
    };

    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = anchor => () => {
        setOpenDialog(true);
        setAnchorEl((e) => ({ ...e, [anchor]: null }));
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ mb: 5, mt: '90px' }}>
                {/* Titulo y botón impulso de producto */}
                <Typography variant='h4'
                    sx={{ fontWeight: 'light' }}
                    gutterBottom>
                    Banners NIN Institute
                </Typography>

                {/* Sección para visualizar y editar sección de banners */}
                <Grid container spacing={4} sx={{ mt: '10px' }}>
                    <Grid item xs={12} md={4}>
                        <BannerCard
                            title={ 'escritorio' }
                            type={ 'desktop' }
                            images={ desktop }
                            anchorEl={ anchorEl1 }
                            anchor={ 'anchorEl1' }
                            open={ open1 }
                            handleClick={ handleClick }
                            handleClose={ handleClose }
                            handleClickOpenDialog={ handleClickOpenDialog }
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <BannerCard
                            title={ 'tableta' }
                            type={ 'tablet' }
                            images={ tablet }
                            anchorEl={ anchorEl2 }
                            anchor={ 'anchorEl2' }
                            open={ open2 }
                            handleClick={ handleClick }
                            handleClose={ handleClose }
                            handleClickOpenDialog={ handleClickOpenDialog }
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <BannerCard
                            title={ 'móvil' }
                            type={ 'mobile' }
                            images={ mobile }
                            anchorEl={ anchorEl3 }
                            anchor={ 'anchorEl3' }
                            open={ open3 }
                            handleClick={ handleClick }
                            handleClose={ handleClose }
                            handleClickOpenDialog={ handleClickOpenDialog }
                        />
                    </Grid>
                </Grid>
                {/* Fin sección para visualizar y editar sección de banners  */}
            </Container>

            <BannersDialog
                handleCloseDialog={ handleCloseDialog }
                openDialog={ openDialog }
                banner={ banner }
                setBanner={ setBanner }
                deletedImages={ deletedImages }
                setDeletedImages={ setDeletedImages }
            />
        </>
    );
};

export default Banners;