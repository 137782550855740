import React from 'react';

import { BLOCK_LENGTH } from '../../../../constants/admin/blog_constants';

import {
    Button, Alert, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, InputLabel, Select,
    MenuItem, Grid
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const BlockDialog = ({ open, setOpen, xs, setXS, md, setMD, insertBlock }) => {
    const matches = useMediaQuery('(max-width:600px)');
    
    const handleDesktopView = ({ target }) => {
        setMD(target.value);
    };

    const handleMovilView = ({ target }) => {
        setXS(target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog
            open={ open }
            onClose={ handleClose }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: matches ? '100%' : '30%' } }}>
            <DialogTitle id="alert-dialog-title">
                Longitud de bloque
            </DialogTitle>
            <DialogContent>
                {/* Longitud de escritorio */}
                <FormControl fullWidth variant="standard"
                    sx={{ width: matches ? '100%' : '50%', mb: 2 }}>
                    <InputLabel id="md-select-label">Longitud de bloque en escritorio</InputLabel>
                    <Select
                        labelId="md-select-label"
                        id="md-select"
                        value={md}
                        label="Longitud de bloque en escritorio"
                        onChange={ handleDesktopView }>
                        {
                            BLOCK_LENGTH.map(e =>
                                <MenuItem key={e}
                                    value={e}>{e}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={md}>
                        <Alert severity="success">Ejemplo de contenido en escritorio</Alert>
                    </Grid>
                </Grid>
                {/* Longitud de móviles */}
                <FormControl fullWidth variant="standard"
                    sx={{ width: matches ? '100%' : '50%', my: 2 }}>
                    <InputLabel id="xs-select-label">Longitud de bloque en móviles</InputLabel>
                    <Select
                        labelId="xs-select-label"
                        id="xs-select"
                        value={xs}
                        label="Longitud de bloque en móviles"
                        onChange={ handleMovilView }>
                        {
                            BLOCK_LENGTH.map(e =>
                                <MenuItem key={e}
                                    value={e}>{e}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={xs}>
                        <Alert severity="success">Ejemplo de contenido en móvil</Alert>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>Cancelar</Button>
                <Button onClick={ insertBlock } autoFocus>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BlockDialog;