import React, { useContext, useRef, useState } from 'react';

import { RouterContext } from '../../context/RouterContext';

import './SignUp.css';

import logo from '../../Assets/Images/logo_NIN.png';

import { signUp } from '../../requests/user';

//MUI
import {
  Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel,
  FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput,
  Typography, useMediaQuery
} from '@mui/material'
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';

//Router dom
import { Link } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#283A49',
  '&:hover': {
    backgroundColor: '#374a5a',
  },
}));

const show_err = { display: "none" };
const hide_err = { display: "flex" };

const init = {
  email: '',
  name: '',
  password: '',
};

const initErr = {
  email_err: false,
  name_err: false,
  password_err: false,
};

const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const SignUp = () => {
  const { userLogin } = useContext(RouterContext);

  const [values, setValues] = useState(init);
  const { email, name, password } = values;

  const [inputErr, setInputErr] = useState(initErr);
  const { email_err, name_err, password_err } = inputErr;

  const email_ref = useRef();
  const name_ref = useRef();
  const password_ref = useRef();

  const handleChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    setValues({ ...values, [name]: value });
    switch (name) {
      case 'email':
        setInputErr({
          ...inputErr,
          email_err: (value.length < 6 ||
            value.length > 120) ? true : false
        });
        break;
      case 'name':
        setInputErr({
          ...inputErr,
          name_err: (value.length < 5 ||
            value.length > 100) ? true : false
        });
        break;
      case 'password':
        setInputErr({
          ...inputErr,
          password_err: (value.length < 5 ||
            value.length > 60) ? true : false
        });
        break;
      default:
        break;
    }
  };

  const formValidator = () => {
    const email_len = email.trim().length;
    const name_len = name.trim().length;
    const password_len = password.trim().length;
    if (email_len > 5 && email_len <= 120 && email_regex.test(email)) {
      if (name_len > 4 && name_len <= 100) {
        if (password_len > 4 && password_len <= 60)
          return true;
        else {
          password_ref.current.select();
          setInputErr({
            ...inputErr,
            password_err: true
          });
        }
      }
      else {
        name_ref.current.select();
        setInputErr({
          ...inputErr,
          name_err: true
        });
      }
    } else {
      email_ref.current.select();
      setInputErr({
        ...inputErr,
        email_err: true
      });
    }
    return false;
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValidator())
      await signUp(values, userLogin);
  };

  const matches = useMediaQuery('max-width: 767px')

  return (
    <div className='bg_signUp'>
      <Container maxWidth='lg'>
        <div className='form_signUp'>
          <Grid container spacing={4}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} order={{ xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
              <Typography variant='h5' color='text.secondary' sx={{ mb: 5, fontWeight: 'bold' }}>
                Registro
              </Typography>
              {/* Email */}
              <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                <InputLabel>Correo electrónico</InputLabel>
                <OutlinedInput
                  id='email_input'
                  placeholder="Correo electrónico"
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailIcon fontSize='small' />
                    </InputAdornment>
                  }
                  label="Correo electrónico"
                  error={email_err}
                  inputRef={email_ref}
                  name='email'
                  value={email}
                  onChange={handleChange}
                />
                <FormHelperText error style={email_err ? hide_err : show_err}>
                  Ingrese su correo electrónico
                </FormHelperText>
              </FormControl>

              {/* nombre */}
              <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                <InputLabel>Nombre y apellidos</InputLabel>
                <OutlinedInput
                  id='name_input'
                  placeholder="Nombre y apellidos"
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonIcon fontSize='small' />
                    </InputAdornment>
                  }
                  label="Nombre y apellidos"
                  error={name_err}
                  inputRef={name_ref}
                  name='name'
                  value={name}
                  onChange={handleChange}
                />
                <FormHelperText error style={name_err ? hide_err : show_err}>
                  Ingrese su nombre
                </FormHelperText>
              </FormControl>

              {/* Password */}
              <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
                <InputLabel>Contraseña</InputLabel>
                <OutlinedInput
                  id='pass_input'
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                  startAdornment={
                    <InputAdornment position="start">
                      <KeyIcon fontSize='small' />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Contraseña"
                  error={password_err}
                  inputRef={password_ref}
                  name='password'
                  value={password}
                  onChange={handleChange}
                />
                <FormHelperText error style={password_err ? hide_err : show_err}>
                  Ingrese su contraseña
                </FormHelperText>
              </FormControl>
              <Box display='flex' justifyContent='space-between' gap='10px' flexWrap='wrap' mb={2}>
                <Box sx={{ my: 'auto' }}>
                  <FormControlLabel
                    control={<Checkbox size='small' />}
                    label={<Typography variant="body2" color="textSecondary">Recordar correo</Typography>}
                    sx={{ fontSize: '10px' }}
                  />
                </Box>
              </Box>
              <ColorButton variant='contained' onClick={handleSubmit}>Acceder</ColorButton>
              <Box sx={{ mt: 2 }}>
                <Typography variant='body1'>
                  ¿Ya tienes cuenta? <Link to='/iniciar-sesion' style={{ textDecoration: 'none' }}>Iniciar sesión</Link>
                </Typography>
              </Box>
            </Grid>
            {
              matches
                ?
                <Grid item xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                  <Divider />
                </Grid>
                :
                <Grid item xl={1} lg={1} md={1} sm={12} xs={12} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                  <Divider orientation="vertical" variant='fullWidth' flexItem sx={{ height: '100%', borderRightWidth: 2 }} />
                </Grid>
            }
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12} sx={{ textAlign: 'center', my: 'auto' }} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
              <img src={logo} alt={logo} width='200' />
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
}

export default SignUp