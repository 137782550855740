import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import BlogItem from './BlogItem';

import { AdminContext } from '../../../context/AdminContext';

//MUI
import {
    Button, Container, Grid, Typography, Alert,
    Stack
} from '@mui/material';
import BookIcon from '@mui/icons-material/Book';

const BlogMain = () => {
    const { data: { blog } } = useContext(AdminContext);

    return (
        <Container maxWidth={ false }
            sx={{ p: 4, height: '100vh' }}>
            <Stack direction="row"
                alignItems="flex-start"
                spacing={12}>
                <Typography variant='h5' gutterBottom>Blog</Typography>
                <Link to="/nin-admin/nuevo-blog" style={{ textDecoration: 'none' }}>
                    <Button
                        variant='outlined'
                        sx={{ mb: 3, textTransform: 'none' }}
                        endIcon={<BookIcon />}
                    >
                        Crear nuevo blog
                    </Button>
                </Link>
            </Stack>
            
            <Grid container spacing={4}>
                {
                    blog.length > 0 ?
                        blog.map((item, i) => (
                            <BlogItem
                                key={i}
                                item={ item }
                            />
                        ))
                    :
                        <Grid item xs={12} md={4}>
                            <Alert severity="info"
                                sx={{ bgcolor: 'background.paper' }}>
                                Sin entradas de blog.
                            </Alert>
                        </Grid>
                }
            </Grid>
        </Container>
    );
};

export default BlogMain;