//React
import { useContext } from 'react';

//Router dom
import { Navigate } from 'react-router-dom';

//Context
import { UIContext } from '../context/UIContext';
import { RouterContext } from '../context/RouterContext';

import { checkIfAdmin } from '../helpers/admin/adminHelper';

export const ProtectedRoute = ({ children }) => {
    const { appUI: { redirectPage } } = useContext(UIContext);
    const { item } = useContext(RouterContext);
    const { email, admin_id } = item;
    return email !== '' ?
        checkIfAdmin(admin_id) ? children : <Navigate to="/" />
    :
        redirectPage === 'logout' ?
            <Navigate to="/iniciar-sesion" />
        :
            <Navigate to="/" />;
};