import ari_img from '../Assets/Images/Expertos/Ari_cisneros_copy.png';
import paola_img from '../Assets/Images/Expertos/Pao_Alvarado_copy.png';
import xunani_img from '../Assets/Images/Expertos/Xunaxi_copy.png';
import ximena_img from '../Assets/Images/Expertos/ximena_copy.png';
import christian_img from '../Assets/Images/Expertos/Christian_copy.png';
import perla_img from '../Assets/Images/Expertos/Perla_copy.png';
import mariana_img from '../Assets/Images/Expertos/Mariana_copy.png';

export const ExpertData = [
    {
        id: 1,
        img: ari_img,
        name: 'Ari Cisneros Hernández',
        workstation: 'Directora Médica NIN Institute',
        professionalLicense: '011993863',
        team: 'Investigación clínica',
        description: 'Maestría en Ciencias Médicas, Miembro de la Sociedad Internacional de Nutrición Renal y Metabolismo (ISRNM). Socio activo de la Asociación Mexicana de Nutriología A.C. Nutrióloga Renal y educadora en diabetes, con experiencia de 12 años en la atención de personas con ERC en sus diversas etapas, ejecutando a la par proyectos de investigación clínica con líneas de investigación como telemedicina, suplementación oral y composición corporal. Autora de capítulos de libro y profesora de cursos y diplomados a nivel nacional e internacional. '
    },
    {
        id: 2,
        img: paola_img,
        name: 'Paola Azucena Alvarado Pelayo',
        workstation: 'Gestora en investigación y nutrición clínica',
        professionalLicense: '13858142',
        team: 'Investigación clínica',
        description: 'Nutrióloga Renal y Educadora en Diabetes. Kidney Health Coach por la American Kidney Fund.  Miembro de la Sociedad Internacional de Nutrición Renal y Metabolismo (ISRNM). Colaboración como autora del libro Nutrición en la enfermedad poliquística renal, manual de inducción para pacientes y familiares de NIN Institute. Investigación y ejecución de protocolos: "Ensayo Clínico sobre nutrición en población renal en etapa de prediálisis". Investigación en el abordaje nutricional enfocado en enfermedades renales. '
    },
    {
        id: 3,
        img: xunani_img,
        name: 'Xunaxi Nahomi Garcia Rodríguez',
        workstation: 'Nutrióloga clínica',
        professionalLicense: '13125434',
        team: 'Investigación clínica',
        description: 'Nutrióloga clínica enfocada en enfermedad renal (diálisis, Hemodiálisis y ERC) con maestría Internacional en Nutrición y Dietética. Experiencia en embarazo, lactancia y nutrición infantil. Docente a nivel licenciatura, Ponente de cursos de nutrición.  Investigación clínica. Me apasiona la alimentación y nutrición de las personas.'
    },
    {
        id: 4,
        img: ximena_img,
        name: 'Ximena Danae Velázquez Reyes',
        workstation: 'Nutrióloga Clínica',
        professionalLicense: [],
        team: 'Nutrición clínica',
        description: 'Lic. en Nutrición, Honorable Socio de la comunidad del Colegio Mexicano de Nutrición Clínica y Terapia Nutricional A.C. Certificada por ISAK nivel 1. Diplomado en Nutrición Clínica y Soporte Nutricional  y enfoque a Nutrición Renal. Me gusta interactuar con mis pacientes, conocerlos y adaptarme a sus creencias, cultura y hábitos. Mi trabajo es enseñarles y hacerles ver aquellas cosas que no sabíamos que perjudicaban nuestra salud, y que con un buen estilo de vida y alimentación podemos llegar a mantener un estado de salud óptimo.'
    },
    {
        id: 5,
        img: christian_img,
        name: 'Christian Heriberto Garcia Castro',
        workstation: 'Gerente de planta',
        professionalLicense: [],
        team: 'Desarrollo de productos',
        description: 'Ingeniero en negocios y manufactura, me apasionan los procesos de transformación y el desarrollo de nuevos productos funcionales, me encargo de la administración de recursos y la coordinación de actividades para la fabricación de nuestros productos.'
    },
    {
        id: 6,
        img: perla_img,
        name: 'Perla Sonora Hernández Hernández',
        workstation: 'Gestor de Investigación y Desarrollo Tecnológico',
        professionalLicense: '12942810',
        team: 'Desarrollo de productos',
        description: 'Nutrióloga, Tecnóloga química en alimentos. Encargada de llevar a cabo el desarrollo e investigación de suplementos alimenticios acorde a las necesidades nutrimentales en diferentes patologías, principalmente enfocados en la enfermedad renal crónica. Apasionada en crear y mejorar productos que contribuyan a las necesidades especiales de los consumidores, cumpliendo con las características nutrimentales y sensoriales, brindando un producto de calidad.'
    },
    {
        id: 7,
        img: mariana_img,
        name: 'Mariana Cervantes Gutiérrez',
        workstation: 'Gestor de Calidad',
        professionalLicense: [],
        team: 'Desarrollo de productos',
        description: 'Lic. En Nutrición y estudiante de Ing. En Alimentos y Biotecnología. Responsable de planificar, implementar y supervisar sistemas y procesos que aseguren la calidad e inocuidad de nuestros productos y servicios; dando seguimiento a las normativas, realizando autorías internas, gestionando la mejora continua y garantizando el cumplimiento de estándares de calidad para satisfacer las expectativas de los clientes y cumplir los requisitos reglamentarios.  Disfruto bastante el trabajar con alimentos y más cuando se relaciona con la salud y bienestar de las personas, siempre con un enfoque de responsabilidad social y mejora continua.'
    }
]