import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';

import { AdminContext } from '../../../context/AdminContext';

import { deleteBlogDB } from '../../../requests/admin/blog';
import { GOOGLE_DRIVE_CDN } from '../../../constants/main_constants';

//MUI
import {
    Card, Grid, CardMedia, CardContent, Typography,
    CardActions, Button
} from '@mui/material';

const BlogItem = ({ item }) => {
    const { title, url, author, imgId } = item;

    const navigate = useNavigate();

    const { setData } = useContext(AdminContext);

    const updateBlog = () => {
        setData(e => ({ ...e, blogState: item }));
        navigate(`/nin-admin/actualizar-blog/${url}`);
    };

    // eliminar blog
    const deleteBlog = () => {
        Swal.fire({
            title: '¿Desea eliminar el blog?',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
                deleteBlogDB(item, setData);
            }
        });
    };

    return (
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card sx={{ width: '100%' }}>
                <CardMedia
                    sx={{ height: 200 }}
                    image={`${GOOGLE_DRIVE_CDN}${imgId}`}
                    title={ title }
                />
                <CardContent sx={{ pb: 0 }}>
                    <Typography variant='h6' component="div">
                        { title }
                    </Typography>
                    <Typography variant='body2' color='text.secondary' gutterBottom>
                        Autor: { author }
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small"
                        onClick={ updateBlog }>Editar</Button>
                    <Button size="small"
                        onClick={ deleteBlog }>Eliminar</Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default BlogItem;