import React from 'react'

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/navigation";
import 'swiper/css/pagination';

//Components
import Experts from './Experts'

const ExpertsList = ({ data }) => {
    return (
        <Swiper
            breakpoints={{
                0: {
                    slidesPerView: 1,
                },
                400: {
                    slidesPerView: 1,
                },
                600: {
                    slidesPerView: 1,
                },
                700: {
                    slidesPerView: 2,
                },
                1000: {
                    slidesPerView: 3,
                },
            }}
            spaceBetween={20}
            pagination={true}
            navigation={{
                nextEl: '.best-selling-swiper-button-next',
                prevEl: '.best-selling-swiper-button-prev',
            }}
            loop={true}
            modules={[Navigation, Pagination]}
        >
            {data.map((item, index) => (
                <SwiperSlide key={item.id}>
                    <Experts item={item} />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default ExpertsList