import React, { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import { UIContext } from '../context/UIContext';
import { AdminContext } from '../context/AdminContext';

import { loadWebsiteData } from '../requests/admin/admin';

import { ShopRoute } from './ShopRoute';
import { PublicRoute } from './PublicRoute';
// import { PrivateRoute } from './PrivateRoute';
import { ProtectedRoute } from './ProtectedRoute';

import { AdminRoutes } from './AdminRoutes';

//Components
import Login from '../Components/Login/Login';
import SignUp from '../Components/SignUp/SignUp';
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';

import { Container, CircularProgress, Alert, Snackbar } from '@mui/material';

const AppRoute = () => {
  const { pathname } = useLocation();
  const { setData } = useContext(AdminContext);
  const { appUI: { loading, mainSnackbar }, handleMainSnackbar } = useContext(UIContext);
  const { open, severity, msg } = mainSnackbar;

  useEffect(() => {
    // carga información para admin
    // enseguida busca sesión de usuario
    loadWebsiteData(setData);
  }, []);
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    handleMainSnackbar(false, '');
  };

  if (loading) {
    return (
      <Container maxWidth='xl' sx={{ textAlign: 'center', mt: 5 }}>
        <CircularProgress />
      </Container>
    );
  }
  
  return (
    <>
      {
        !pathname.includes('nin-admin') &&
        <>
          {
            pathname !== '/' && <Header />
          }
        </>
      }
      <Routes>
        <Route path="/iniciar-sesion" element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route path="/registro" element={
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        } />
        <Route path="/*" element={
          <ShopRoute />
        } />
        {/* <Route path="/mi-cuenta/*" element={
          <PrivateRoute>
            <UserRoutes />
          </PrivateRoute>
        } /> */}
        <Route path="/nin-admin/*" element={
          <ProtectedRoute>
            <AdminRoutes />
          </ProtectedRoute>
        } />
      </Routes>
      {
        !pathname.includes('nin-admin') &&
        <Footer />
      }

      <Snackbar open={ open }
        autoHideDuration={6000}
        onClose={ handleClose }>
        <Alert
          onClose={ handleClose }
          severity={ severity }
          variant="filled"
          sx={{ width: '100%' }}
        >
          { msg }
        </Alert>
      </Snackbar>
    </>
  );
};

export default AppRoute;