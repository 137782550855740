import React from 'react'

//CSS
import './Footer.css'

//Router dom
import { Link } from 'react-router-dom'

//MUI
import { Box, Container, Grid, IconButton, Stack, Typography } from '@mui/material'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <footer className='footer'>
      <Container maxWidth="xxl" sx={{ py: 4, textAlign: 'center' }}>
        <Grid container spacing={3}>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{ mx: 'auto' }}>
            <Typography variant='body1'>
              <a href='mailto:servicioalcliente@nin.com.mx' style={{ color: '#fff', textDecoration: 'none' }}>servicioalcliente@nin.com.mx</a> | Whatsapp: <a href='https://api.whatsapp.com/send?phone=+523318011239&text=Hola, me%20gustaría%20más%20información%20acerca.' target='_blank' rel="noopener noreferrer" style={{ color: '#fff', textDecoration: 'none' }}>33 1801 1239</a>
            </Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{ mx: 'auto' }}>
            <Typography variant='body1'><Link to='/aviso-de-privacidad' style={{ color: '#fff', textDecoration: 'none' }}>Áviso de privacidad</Link></Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12} sx={{ mx: 'auto' }}>
            <Typography variant='body1'><Link to='/terminos-y-condiciones' style={{ color: '#fff', textDecoration: 'none' }}>Términos y condiciones</Link></Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4 }}>
          <Stack direction='row' spacing={2} justifyContent="center">
            <IconButton size="large" LinkComponent='a' href='https://www.linkedin.com/company/nininstitute/?originalSubdomain=mx' target='_blank' sx={{ color: '#fff' }}>
              <LinkedInIcon />
            </IconButton>
            <IconButton size="large" LinkComponent='a' href='https://www.facebook.com/NINInstituteMexico/' target='_blank' sx={{ color: '#fff' }}>
              <FacebookIcon />
            </IconButton>
            <IconButton size="large" LinkComponent='a' href='https://www.youtube.com/channel/UCjr9NsiichDH8HP2A_LdSlA' target='_blank' sx={{ color: '#fff' }}>
              <YouTubeIcon />
            </IconButton>
            <IconButton size="large" LinkComponent='a' href='https://www.instagram.com/nin.institute/' target='_blank' sx={{ color: '#fff' }}>
              <InstagramIcon />
            </IconButton>
          </Stack>
        </Box>
      </Container>
      {/* Copyright */}
      <div className="copyright">
        <Typography variant='body1'>© {new Date().getFullYear()} Todos los derechos reservados.</Typography>
      </div>
      {/* Copyright */}
    </footer>
  )
}

export default Footer