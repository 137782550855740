import { basicTimerMsg } from "../../helpers/admin/msgHelper";

// validar cambios en valores
export const blogChangesValidator = ({ title, author, selectedCategory, imgFile, row }) => {
    const v1_len = title.length;
    const v2_len = author.length;
    let colCount = 0;
    const content = row.filter((e, i) => i !== 0);
    if (
        (v1_len > 3) || (v2_len > 2) ||
        selectedCategory !== '' || imgFile
    ) return true;
    else {
        for (const e of content) {
            if (e.col.length > 0)
                colCount ++;
        };
        if (colCount > 0)
            return true;
        return false;
    }
};

export const blogEditionChangesValidator = (firstModel, secondModel) => {
    const { title, category_id, publication_date } = firstModel;
};

// validación formulario
export const blogFormValidator = ({ title, author, publication_date, selectedCategory, imgFile, row }) => {
    const v1_len = title.length;
    const v2_len = author.length;
    let colCount = 0;
    const content = row.filter((e, i) => i !== 0);
    if (v1_len > 3 && v1_len <= 200) {
        if (v2_len > 2 && v2_len <= 120) {
            if (publication_date) {
                if (selectedCategory !== '') {
                    if (imgFile) {
                        for (const e of content) {
                            if (e.col.length > 0)
                                colCount ++;
                        };
                        if (colCount > 0)
                            return true;
                        else
                            basicTimerMsg('Ingrese contenido al blog');
                    } else
                        basicTimerMsg('Ingrese imagen de portada');
                } else
                    basicTimerMsg('Seleccione una categoría');
            } else
                basicTimerMsg('Ingrese fecha de publicación');
        } else
            basicTimerMsg('Ingrese autor de blog');
    } else
        basicTimerMsg('Ingrese título de blog');
    return false;
};

// validación formulario de edición
// export const blogEditionFormValidator = ({ title, author, publication_date, selectedCategory, row }) => {
//     const v1_len = title.length;
//     const v2_len = author.length;
//     let colCount = 0;
//     const content = row.filter((e, i) => i !== 0);
//     if (v1_len > 3 && v1_len <= 200) {
//         if (v2_len > 2 && v2_len <= 120) {
//             if (publication_date) {
//                 if (selectedCategory !== '') {
//                     for (const e of content) {
//                         if (e.col.length > 0)
//                             colCount ++;
//                     };
//                     if (colCount > 0)
//                         return true;
//                     else
//                         basicTimerMsg('Ingrese contenido al blog');
//                 } else
//                     basicTimerMsg('Seleccione una categoría');
//             } else
//                 basicTimerMsg('Ingrese fecha de publicación');
//         } else
//             basicTimerMsg('Ingrese autor de blog');
//     } else
//         basicTimerMsg('Ingrese título de blog');
//     return false;
// };