import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { Draggable } from 'react-beautiful-dnd';

import { AdminContext } from '../../../../context/AdminContext';

import {
    Grid, Paper, IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const BlogColEdition = ({ col, j, setOpen, row, setRow, oldFiles, setOldFiles }) => {
    const { setData } = useContext(AdminContext);
    const { item, type, xs, md, id, fileId, cloud_file } = col;

    const handleClickOpen = () => {
        setData(e => ({ ...e, blockState: col }));
        setOpen(true);
    };

    const deleteBlock = () => {
        Swal.fire({
            title: '¿Eliminar el bloque?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                const content = row.map(e => (
                    {    
                        ...e,
                        col: [
                            ...e.col.filter(u => u.id !== id)
                        ]
                    }
                ));
                setRow(content);
                if (type === 'img' && cloud_file)
                    setOldFiles([...oldFiles, fileId]);
            }
        });
    };

    return (
        <Draggable draggableId={id} index={j}>
            {(provided) => (
                <Grid item xs={xs} md={md}
                    ref={ provided.innerRef }
                    { ...provided.draggableProps }
                    { ...provided.dragHandleProps }>
                    <Paper square sx={{ p: 1 }}>
                        <div className="myClass" dangerouslySetInnerHTML={{ __html: item }}></div>
                        <IconButton aria-label="update" size="small"
                            onClick={ handleClickOpen }>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton aria-label="delete" size="small"
                            onClick={ deleteBlock }>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Paper>
                </Grid>
            )}
        </Draggable>
    );
};

export default BlogColEdition;