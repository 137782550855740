import React, { useState } from 'react'

import img_1 from '../../../Assets/Resources/Charlas/Foro nefropatologías.jpeg'
import img_2 from '../../../Assets/Resources/Charlas/foro nefropatologias.jpg'
import img_3 from '../../../Assets/Resources/Charlas/foro nefropatologias2.jpg'

//Gallery
import { Gallery } from "react-grid-gallery";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'

//MUI
import { Container, Typography } from '@mui/material'

const images = [
  {
    src: img_1,
    original: img_1,
    width: 260,
    height: 174,
  },
  {
    src: img_2,
    original: img_2,
    width: 360,
    height: 212,
  },
  {
    src: img_3,
    original: img_3,
    width: 300,
    height: 213,
  },
]

const Charlas = () => {

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <Container maxWidth='xl' sx={{ my: 8 }}>
      <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
        Charlas
      </Typography>
      <div>
        <Gallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>
    </Container>
  )
}

export default Charlas