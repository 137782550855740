import React from 'react';

import { GOOGLE_DRIVE_CDN } from '../../../constants/main_constants';

//MUI
import {
    Box, Card, CardContent, IconButton, Menu, MenuItem, Typography,
    Stack, ListItemIcon, ListItemText, Alert
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const BannerCard = ({
    title, type, images, anchorEl, anchor, open, handleClick,
    handleClose, handleClickOpenDialog
}) => {
    return (
        <Card
            sx={{
                borderRadius: '8px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
            }}
        >
            <Menu
                anchorEl={ anchorEl }
                open={ open }
                onClose={ handleClose(anchor) }
            >
                <MenuItem onClick={ handleClickOpenDialog(anchor) }>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Editar</ListItemText>
                </MenuItem>
            </Menu>

            <CardContent>
                <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='15px'>
                    <Typography variant='body1' sx={{ my: 'auto' }}>
                        { `Banners para ${title}` }
                    </Typography>
                    
                    <IconButton sx={{ my: 'auto' }}
                        onClick={ handleClick(type, anchor) } >
                        <MoreVertIcon />
                    </IconButton>
                </Box>

                {
                    images.length > 0 ?
                        <div className='impulse_grid_container'>
                            {
                                images.map(({ banner_id, imgId }) => (
                                    <Stack key={banner_id}>
                                        <div className='impulse_grid_item impulse_image'>
                                            <div className="impulse_img">
                                                <img src={`${GOOGLE_DRIVE_CDN}${imgId}`}
                                                    alt="banner-nin-institute" />
                                            </div>
                                        </div>
                                    </Stack>
                                ))
                            }
                        </div>
                    :
                        <Alert variant="outlined" severity="info">
                            Sin banners disponibles
                        </Alert>
                }
            </CardContent>
        </Card>
    );
};

export default BannerCard;