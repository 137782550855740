import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';

//MUI
import Blog from "../Components/Blog/Blog";
import Index from "../Components/Index/Index";
import About from "../Components/About/About";
import Products from "../Components/Products/Products";
import NinAcademy from "../Components/NinAcademy/NinAcademy";
import Desarrollo from "../Components/Desarrollo/Desarrollo";
import BlogContainer from "../Components/Blog/BlogContainer";
import Charlas from "../Components/NinAcademy/Charlas/Charlas";
import Webinars from "../Components/NinAcademy/Webinars/Webinars";
import Ponencias from "../Components/NinAcademy/Ponencias/Ponencias";
import Investigation from "../Components/Investigation/Investigation";
import PoliticaPrivacidad from "../Components/Avisos/PoliticaPrivacidad";
import TerminosCondiciones from "../Components/Avisos/TerminosCondiciones";
import Capacitaciones from "../Components/NinAcademy/Capacitaciones/Capacitaciones";

export const ShopRoute = () => {

    return (
        <>
            <Routes>
                <Route path="" element={<Index />} />
                <Route path='/blog' element={<BlogContainer />} />
                <Route path='/blog/:url' element={<Blog />} />
                <Route path='/nosotros' element={<About />} />
                <Route path='/desarrollo' element={<Desarrollo />} />
                <Route path='/investigacion' element={<Investigation />} />
                <Route path='/linea-de-productos' element={<Products />} />
                <Route path='/nin-academy' element={<NinAcademy />} />
                <Route path='/webinars' element={<Webinars />} />
                <Route path='/ponencias' element={<Ponencias />} />
                <Route path='/charlas' element={<Charlas />} />
                <Route path='/capacitaciones' element={<Capacitaciones />} />
                <Route path='/aviso-de-privacidad' element={<PoliticaPrivacidad />} />
                <Route path='/terminos-y-condiciones' element={<TerminosCondiciones />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </>
    )
};