import React, { useContext } from 'react';

import NewCategory from './child/NewCategory';

import { AdminContext } from '../../../context/AdminContext';

import {
    List, Grid, IconButton, FormControl,
    FormLabel, FormControlLabel, Radio, RadioGroup, Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const BlogCategory = ({ selectedCategory, setSelectedCategory, setOpen }) => {
    const { data: { categories } } = useContext(AdminContext);

    const handleCategorySelect = ({ target }) => {
        setSelectedCategory(target.value);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <FormControl>
                        <FormLabel id="blog-category-radio-group-label">
                            Categoríass
                            <Tooltip title="Editar lista de categorías"
                                onClick={ () => setOpen(true) }>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="blog-category-radio-group-label"
                            name="radio-buttons-group"
                            value={ selectedCategory }
                            onChange={ handleCategorySelect }
                        >
                            {
                                categories.map(({ category_id, category }) => {
                                    return (
                                        <FormControlLabel
                                            key={ category_id }
                                            value={ category_id }
                                            control={<Radio />}
                                            label={ category }
                                        />
                                    )
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </List>
            </Grid>
            <Grid item xs={12} md={5}>
                <NewCategory
                    setSelectedCategory={ setSelectedCategory }
                />
            </Grid>
        </Grid>
    );
};

export default BlogCategory;