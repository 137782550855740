import React, { useState, useEffect } from 'react'

//Components
import ExpertsList from '../ExpertsList'

//Utils
import { CustomFetch } from '../../../Utils/CustomFetch'
import { ExpertData } from '../../../Data/ExpertsData'

const NutriClinic = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
    CustomFetch(ExpertData)
      .then(res => {
        setData(res.filter(e => e.team === 'Nutrición clínica'))
        setLoading(true)
      })
  }, [])

  return (
    <>
      {
        loading
          ?
          <ExpertsList data={data} />
          :
          <>cargando...</>
      }
    </>
  )
}

export default NutriClinic