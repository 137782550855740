import { valMsg } from "../../helpers/admin/msgHelper";

// validación formulario -> registro de usuario administrador
export const userFormValidator1 = (
    { email, username, password, password_c, role },
    { eInputRef, uInputRef, p1InputRef, p2InputRef, rInputRef },
    setInputErr
) => {
    // longitud de campos
    const email_len = email.trim().length;
    const username_len = username.trim().length;
    const pwd_len = password.trim().length;
    const role_len = role.trim().length;

    if (email_len > 5 && email_len <= 120) {
        if (username_len > 2 && username_len <= 100) {
            if (pwd_len > 4 && pwd_len <= 60) {
                if (password_c === password) {
                    if (role_len > 2 && role_len <= 30)
                        return true;
                    else {
                        rInputRef.current.select();
                        valMsg('warning', 'Registro',
                            'Ingrese rol del administrador');
                    }
                } else {
                    p2InputRef.current.select();
                    valMsg('warning', 'Registro',
                        'Las contraseñas no son iguales');
                }
            } else {
                p1InputRef.current.select();
                valMsg('warning', 'Registro',
                    'Ingrese una contraseña segura');
            }
        } else {
            uInputRef.current.select();
            valMsg('warning', 'Registro',
                'Ingrese un nombre de usuario apropiado');
        }
    } else {
        eInputRef.current.select();
        valMsg('warning', 'Registro',
            'Ingrese un correo electrónico válido');
    }
    // establece errores en los campos
    setInputErr(e => ({
        ...e,
        email_err: (email_len < 6 || email_len > 120)
            ? true : false,
        username_err: (username_len < 3 || username_len > 100)
            ? true : false,
        pwd1_err: (pwd_len < 5 ||
            pwd_len > 60) ? true : false,
        pwd2_err: (password_c !== password)
            ? true : false,
        role_err: (role_len < 3 || role_len > 30)
            ? true : false
    }));

    return false;
};