import React, { useState } from 'react'

import img_1 from '../../../Assets/Resources/Capacitaciones/img_2.jpeg'
import img_2 from '../../../Assets/Resources/Capacitaciones/IMG_8140.png'

//Gallery
import { Gallery } from "react-grid-gallery";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'

//MUI
import { Container, Typography } from '@mui/material'

const images = [
    {
        src: img_1,
        original: img_1,
        width: 320,
        height: 174,
    },
    {
        src: img_2,
        original: img_2,
        width: 320,
        height: 212,
    },
]

const Capacitaciones = () => {

    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);


    return (
        <Container maxWidth='xl' sx={{ my: 8 }}>
            <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                Capacitaciones
            </Typography>
            <div>
                <Gallery
                    images={images}
                    onClick={handleClick}
                    enableImageSelection={false}
                />
                {!!currentImage && (
                    <Lightbox
                        mainSrc={currentImage.original}
                        mainSrcThumbnail={currentImage.src}
                        nextSrc={nextImage.original}
                        nextSrcThumbnail={nextImage.src}
                        prevSrc={prevImage.original}
                        prevSrcThumbnail={prevImage.src}
                        onCloseRequest={handleClose}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                    />
                )}
            </div>
        </Container>
    )
}

export default Capacitaciones