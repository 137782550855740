//React
import { useContext } from 'react';

//Router dom
import { Navigate } from 'react-router-dom';

//Context
import { UIContext } from '../context/UIContext';
import { RouterContext } from '../context/RouterContext';

import { checkIfAdmin } from '../helpers/admin/adminHelper';

export const PublicRoute = ({ children }) => {
    const { appUI: { redirectPage } } = useContext(UIContext);
    const { item: { email, admin_id } } = useContext(RouterContext);
    switch(true) {
        case (email !== '' && redirectPage === 'index'):
            return <Navigate to="/" />;
        case (email !== '' && checkIfAdmin(admin_id)):
            switch(redirectPage) {
                case 'admin':
                    return <Navigate to="/nin-admin" />;
                default:
                    return <Navigate to={ `/${redirectPage}` } />;
            }
        case (email !== '' && !checkIfAdmin(admin_id)):
            switch(redirectPage) {
                case 'user':
                    return <Navigate to="/desarrollo" />;
                default:
                    return <Navigate to={ `/${redirectPage}` } />;
            }
        default: return children;
    };
};
