import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';

import { AdminContext } from '../../../context/AdminContext';

import { deleteAdminUser } from '../../../requests/admin/adminUser';

import {
    Card, CardContent, CardHeader, IconButton, Avatar,
    Menu, MenuItem, ListItemIcon, Grid, Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const UserListItem = ({ admin, setModal, setActiveAdmin }) => {
    const { email, username, role } = admin;

    const { setData } = useContext(AdminContext);

    // eliminar admin
    const adminDelete = () => {
        setMenu(null);
        Swal.fire({
            title: `¿Desea eliminar al administrador ${username}?`,
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deleteAdminUser(admin, setData);
            }
        });
    };

    //Menú para administrar usuarios
    const [menu, setMenu] = useState(null);
    const openMenu = Boolean(menu);
    const handleClickMenu = (event) => {
        setMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenu(null);
    };

    const handleClickOpenDialog = () => {
        setMenu(null);
        setActiveAdmin(e => ({ ...e, ...admin }));
        setModal(e => ({ ...e, status: true, action: 'edit' }))
    };

    return (
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            {/* Card de administador */}
            <Card sx={{ maxWidth: '100%' }}>
                {/* Avatar y nombre de usuario */}
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#1976D2' }} aria-label="recipe">
                            { username.charAt(0) }
                        </Avatar>
                    }
                    action={
                        <IconButton
                            onClick={ handleClickMenu }
                            aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={username}
                />
                {/* Fin Avatar y nombre de usuario */}

                {/* Menú para administrar */}
                <Menu
                    id="basic-admin-menu"
                    anchorEl={ menu }
                    open={ openMenu }
                    onClose={ handleCloseMenu }
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={ handleClickOpenDialog }>
                        <ListItemIcon>
                            <i className="fa-solid fa-pen-to-square"></i>
                        </ListItemIcon>
                        Administrar
                    </MenuItem>
                    <MenuItem onClick={ adminDelete }>
                        <ListItemIcon>
                            <i className="fa-solid fa-trash"></i>
                        </ListItemIcon>
                        Eliminar usuario
                    </MenuItem>
                </Menu>
                {/* Fin Menú para administrar */}

                {/* Contenido card */}
                <CardContent>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                        { role }
                    </Typography>
                    <Typography variant='body1' color='text.secondary'>
                        <i className="fa-solid fa-envelope"></i> { email }
                    </Typography>
                </CardContent>
                {/* Fin Contenido card */}
            </Card>
            {/* Fin Card de administador */}
        </Grid>
    );
};

export default UserListItem;