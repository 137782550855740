import React, { useContext, useState } from 'react';

import { AdminContext } from '../../../../context/AdminContext';
import { addBlogCategory } from '../../../../requests/admin/blog';
import { basicTimerMsg } from '../../../../helpers/admin/msgHelper';

import {
    Stack, TextField, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';

const NewCategory = ({ setSelectedCategory }) => {
    const matches = useMediaQuery('(max-width:600px)');
    const { setData } = useContext(AdminContext);
    // campo
    const [category, setCategory] = useState('');
    
    // campo categoría
    const handleOnChange = ({ target }) => {
        setCategory(target.value);
    };

    // guardar categoría
    const save = async () => {
        if (category.length > 3 && category.length <= 100) {
            if (await addBlogCategory(category, setData, setSelectedCategory))
                setCategory(''); // limpiar TextField
        } else
            basicTimerMsg('Ingrese título de categoría');
    };

    return (
        <Stack direction="row" sx={{ mt: !matches && 1 }}>
            <TextField fullWidth
                variant='outlined'
                label="Nueva categoría"
                size='small'
                name='category'
                inputProps={{ maxLength: 100 }}
                value={ category }
                onChange={ handleOnChange }
            />
            <IconButton aria-label="add"
                onClick={ save }>
                <AddIcon />
            </IconButton>
        </Stack>
    );
};

export default NewCategory;