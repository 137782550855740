import React, { useContext, useRef, useState } from 'react';

import { RouterContext } from '../../context/RouterContext';

import './Login.css';

import logo from '../../Assets/Images/logo_NIN.png';

import { login } from '../../requests/user';

//MUI
import {
    Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel,
    FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput,
    Typography, useMediaQuery
} from '@mui/material'
import { styled } from '@mui/material/styles';
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//Router dom
import { Link } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#283A49',
    '&:hover': {
        backgroundColor: '#374a5a',
    },
}));

const show_err = { display: "none" };
const hide_err = { display: "flex" };

const init = {
    email: '',
    password: '',
};

const initErr = {
    email_err: false,
    password_err: false,
};

const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const Login = () => {
    const { userLogin } = useContext(RouterContext);

    const [values, setValues] = useState(init);
    const { email, password } = values;

    const [inputErr, setInputErr] = useState(initErr);
    const { email_err, password_err } = inputErr;

    const email_ref = useRef();
    const password_ref = useRef();

    const handleChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
        switch (name) {
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'password':
                setInputErr({
                    ...inputErr,
                    password_err: (value.length < 5 ||
                        value.length > 60) ? true : false
                });
                break;
            default:
                break;
        }
    };

    const formValidator = () => {
        const email_len = email.trim().length;
        const password_len = password.trim().length;
        if (email_len > 5 && email_len <= 100 && email_regex.test(email)) {
            if (password_len > 4 && password_len <= 60)
                return true;
            else {
                password_ref.current.select();
                setInputErr({
                    ...inputErr,
                    password_err: true
                });
            }
        } else {
            email_ref.current.select();
            setInputErr({
                ...inputErr,
                email_err: true
            });
        }
        return false;
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (formValidator())
            await login(values, userLogin);
    }

    const matches = useMediaQuery('max-width: 767px')

    return (
        <div className='bg_login'>
            <Container maxWidth='lg'>
                <div className='form_login'>
                    <Grid container spacing={4}>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} sx={{ textAlign: 'center', my: 'auto' }}>
                            <img src={logo} alt={logo} width='200' />
                        </Grid>
                        {
                            matches
                                ?
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                :
                                <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
                                    <Divider orientation="vertical" variant='fullWidth' flexItem sx={{ height: '100%', borderRightWidth: 2 }} />
                                </Grid>
                        }
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Typography variant='h5' color='text.secondary' sx={{ mb: 5, fontWeight: 'bold' }}>
                                Iniciar sesión
                            </Typography>
                            {/* Email */}
                            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                                <InputLabel>Correo electrónico</InputLabel>
                                <OutlinedInput
                                    id='email_input'
                                    placeholder="Correo electrónico"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <EmailIcon fontSize='small' />
                                        </InputAdornment>
                                    }
                                    label="Correo electrónico"
                                    error={email_err}
                                    inputRef={email_ref}
                                    name='email'
                                    value={email}
                                    onChange={handleChange}
                                />
                                <FormHelperText error style={email_err ? hide_err : show_err}>
                                    Ingrese su correo electrónico
                                </FormHelperText>
                            </FormControl>

                            {/* Password */}
                            <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
                                <InputLabel>Contraseña</InputLabel>
                                <OutlinedInput
                                    id='pass_input'
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Contraseña"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <KeyIcon fontSize='small' />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Contraseña"
                                    error={password_err}
                                    inputRef={password_ref}
                                    name='password'
                                    value={password}
                                    onChange={handleChange}
                                />
                                <FormHelperText error style={password_err ? hide_err : show_err}>
                                    Ingrese su contraseña
                                </FormHelperText>
                            </FormControl>
                            <Box display='flex' justifyContent='space-between' gap='10px' flexWrap='wrap' mb={2}>
                                <Box sx={{ my: 'auto' }}>
                                    <FormControlLabel
                                        control={<Checkbox size='small' />}
                                        label={<Typography variant="body2" color="textSecondary">Recordar correo</Typography>}
                                        sx={{ fontSize: '10px' }}
                                    />
                                </Box>
                                <Box sx={{ my: 'auto' }}>
                                    <a href='#!' style={{ fontSize: '14px' }}>Olvidé mi contraseña</a>
                                </Box>
                            </Box>
                            <ColorButton variant='contained' onClick={handleSubmit}>Acceder</ColorButton>
                            <Box sx={{mt: 2}}>
                                <Typography variant='body1'>
                                    ¿No tienes cuenta? <Link to='/registro' style={{textDecoration: 'none'}}>Registrate</Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default Login