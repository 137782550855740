const base_url = process.env.REACT_APP_NEFRO_API_URL; // servidor en Node.js (peticiones - MongoDB)

// peticiones de aplicación
const request = (endpoint, obj, method = 'GET') => {
    const url = `${base_url}/${endpoint}`;
    if (method === 'GET') {
        return fetch(url, { method });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(obj)
        });
    }
};

// petición restringida (sólo administradores)
const protectedReq = (endpoint, obj, method = 'GET') => {
    const url = `${base_url}/${endpoint}`;
    // token guardado
    const token = localStorage.getItem('token') || '';
    if (method === 'GET') {
        return fetch(url, {
            method,
            headers: {
                'x-token': token
            }
        });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(obj)
        });
    }
};

export {
    request,
    protectedReq,
};