import React from "react";
import { QUILL_FORMATS } from "../../../constants/admin/blog_constants";

const renderOptions = (formatData, i) => {
    const { className, options } = formatData;
    return (
        <select key={i} className={className}>
            <option></option>
            {
                options.map((value, index) => {
                    return (
                        <option value={value} key={index}></option>
                    )
                })
            }
        </select>
    )
};
const renderSingle = (formatData, i) => {
    const { className, value } = formatData;
    return (
        <button key={i} className={className} value={value}></button>
    )
};
const CustomToolbar = () => (
    <div id="toolbar">
        {
            QUILL_FORMATS.map((classes, index) => {
                return (
                    <span className="ql-formats" key={index}>
                        {
                            classes.map((formatData, i) => {
                                return formatData.options ? renderOptions(formatData, i) : renderSingle(formatData, i)
                            })
                        }
                    </span>
                )
            })
        }
    </div>
);

export default CustomToolbar;