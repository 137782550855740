import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import BlogColEdition from './BlogColEdition';

import { basicMsg, simpleConfirmDialog } from '../../../../helpers/admin/msgHelper';

import {
    Grid, Card, Alert, Divider, Chip, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const BlogRowEdition = ({ setOpen, row, setRow, oldFiles, setOldFiles, rowsRef }) => {
    // bloque movido de posición
    const onDragEnd = (result, row, setRow) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = row.find(e => e.id === source.droppableId);
            const i = sourceColumn ? row.findIndex(e => e.id === source.droppableId) : 0;

            const destColumn = row.find(e => e.id === destination.droppableId);
            const j = destColumn ? row.findIndex(e => e.id === destination.droppableId) : 0;

            const sourceItems = sourceColumn ? [...sourceColumn.col] : [];
            const destItems = destColumn ? [...destColumn.col] : [];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            let content = [...row];
            content[i] = { ...content[i], col: sourceItems };
            content[j] = { ...content[j], col: destItems };
            setRow(content);
        } else {
            const column = row.find(e => e.id === source.droppableId);
            const i = column ? row.findIndex(e => e.id === source.droppableId) : 0;
            const copiedItems = column ? [...column.col] : [];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            let content = [...row];
            content[i] = { ...content[i], col: copiedItems };
            setRow(content);
        }
    };

    const removeRow = row_pos => async () => {
        if (row_pos === 0)
            basicMsg('No puede eliminar la fila de bloques añadidos');
        else {
            if (await simpleConfirmDialog(`¿Eliminar la fila #${row_pos} y su contenido?`, 'Sí, continuar')) {

                const content = row.filter((e, i) => i !== row_pos);
                setRow(content);
                const deletedRow = row[row_pos];
                for (const col of deletedRow.col) {
                    if (col.type === 'img' && col.cloud_file)
                        setOldFiles(e => ([...e, col.fileId]));
                };
            }
        }
    };
    
    return (
        <DragDropContext
            onDragEnd={ (result) => onDragEnd(result, row, setRow) }>
            <Card sx={{ mb: 2 }}>
                {
                    row.map(({ col, id }, i) => {
                        return (
                            <div key={id} ref={ ref => { if (ref) rowsRef.current[i] = ref } }>
                                <Droppable droppableId={id}
                                    direction="horizontal">
                                    {(provided, snapshot) => (
                                        <Grid container spacing={1} my={5} px={2}
                                            ref={ provided.innerRef }
                                            { ...provided.droppableProps }>
                                            <Grid item xs={12}>
                                                <Divider>
                                                    <Chip
                                                        label={
                                                            i === 0 ? 'Bloques guardados (NO VISIBLE)' : `FILA ${i}`
                                                        }
                                                        variant={
                                                            i === 0 ? 'outlined' : 'filled'
                                                        }
                                                        color={
                                                            i === 0 ? 'default' : 'primary'
                                                        }
                                                        icon={
                                                            i === 0 ? <VisibilityOffIcon /> : <VisibilityIcon />
                                                        }
                                                    />
                                                    {
                                                        i !== 0 &&
                                                            <IconButton aria-label="delete" size="small"
                                                                onClick={ removeRow(i) }>
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                    }
                                                </Divider>
                                            </Grid>
                                            {
                                                col.length > 0 ?
                                                    col.map((col, j) =>
                                                        <BlogColEdition
                                                            key={col.id}
                                                            col={ col } j={j}
                                                            setOpen={ setOpen } 
                                                            row={ row } setRow={ setRow }
                                                            oldFiles={ oldFiles }
                                                            setOldFiles={ setOldFiles }
                                                        />
                                                    )
                                                :
                                                    <Grid item xs={12}>
                                                        <Alert severity="info">No hay bloques en la fila</Alert>
                                                    </Grid>
                                            }
                                            { provided.placeholder }
                                        </Grid>
                                    )}
                                </Droppable>
                            </div>
                        )
                    })
                }
            </Card>
        </DragDropContext>
    );
};

export default BlogRowEdition;