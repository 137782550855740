import { createContext, useState } from "react";

export const UIContext = createContext();
const { Provider } = UIContext;

const AppUIContextProvider = ({ children }) => {
    const [appUI, setAppUI] = useState({
        loading: true, // cargando sesión de usuario
        redirectPage: 'index', // página a la que usuario es redireccionado al iniciar sesión
        mainSnackbar: {
            open: false,
            severity: 'success',
            msg: ''
        }
    });

    // const startLoading = () => {
    //     setAppUI(e => ({ ...e, loading: true }));
    // };
    const stopLoading = (redirectPage) => {
        setAppUI(e => ({
            ...e, loading: false,
            redirectPage
        }));
    };
    const setRedirectPage = (redirectPage) => {
        setAppUI(e => ({ ...e, redirectPage }));
    };
    const handleMainSnackbar = (open, severity, msg) => {
        setAppUI(e => ({ ...e,
            mainSnackbar: { open, severity, msg }
        }));
    };

    return (
        <Provider value={{ appUI, stopLoading, setRedirectPage, handleMainSnackbar }}>
            { children }
        </Provider>
    );
};

export default AppUIContextProvider;