import React, { useContext, useEffect, useRef, useState } from 'react';

import { AdminContext } from '../../../context/AdminContext';

import { saveAdminUser, updateAdminUser } from '../../../requests/admin/adminUser';
import { userFormValidator1 } from '../../../validators/admin/AdminUserValidator';

import { ADMIN_ERROR_FIELDS, ADMIN_FIELDS } from '../../../constants/admin/user_constants';

import {
    Button, FormControl, FormHelperText, InputAdornment, InputLabel,
    OutlinedInput, TextField, Dialog, DialogContent, DialogTitle, Grid,
    DialogActions, IconButton
} from '@mui/material';
import {
    AccountCircle, Visibility, VisibilityOff
} from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

// estilos para mostrar/ocultar errores
const hideErrStyle = { display: "none" };
const showErrStyle = { display: "flex" };

// const closeAction = {
//     status: false,
//     action: 'add'
// };

const UserModal = ({ activeAdmin, modal, setModal }) => {
    const { status, action } = modal;

    const { setData } = useContext(AdminContext);

    // referencias a campos
    const eInputRef = useRef();
    const uInputRef = useRef();
    const p1InputRef = useRef();
    const p2InputRef = useRef();
    const rInputRef = useRef();

    const [values, setValues] = useState(ADMIN_FIELDS);
    const { username, email, password, password_c, role } = values;
    // errores en campos
    const [inputErr, setInputErr] = useState(ADMIN_ERROR_FIELDS);
    const { username_err, email_err, pwd1_err, pwd2_err, role_err } = inputErr;
    // mostrar/ocultar contraseña
    const [pwdToggle, setPwdToggle] = useState({
        pwd1: false,
        pwd2: false,
    });
    const { pwd1, pwd2 } = pwdToggle;

    useEffect(() => {
        if (status) { // se cierra modal
            if (action === 'edit')
                setValues({ ...activeAdmin, password: '', password_c: '' });
        }

        // if (!status) { // se cierra modal
        //     // limpiar campos
        //     setValues(ADMIN_FIELDS);
        //     setInputErr(ADMIN_ERROR_FIELDS);
        // } else {
        //     if (action === 'edit')
        //         setValues({ ...activeAdmin, password: '', password_c: '' });
        // }
    }, [modal]);

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
        switch (name) {
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 120) ? true : false
                });
                break;
            case 'username':
                setInputErr({
                    ...inputErr,
                    username_err: (value.length < 3 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'password':
                setInputErr({
                    ...inputErr,
                    pwd1_err: (value.length < 5 ||
                        value.length > 60) ? true : false,
                    pwd2_err: (password_c !== value) ? true : false
                });
                break;
            case 'password_c':
                setInputErr({
                    ...inputErr,
                    pwd2_err: (value !== password) ? true : false
                });
                break;
            case 'role':
                setInputErr({
                    ...inputErr,
                    role_err: (value.length < 3 ||
                        value.length > 30) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // validación formulario
    const formValidator = () => {
        return userFormValidator1(values, {
            eInputRef, uInputRef, p1InputRef, p2InputRef, rInputRef
        }, setInputErr);
    };

    const handlePwd1Toggle = () => {
        setPwdToggle({ ...pwdToggle, pwd1: !pwd1 });
    };
    const handlePwd2Toggle = () => {
        setPwdToggle({ ...pwdToggle, pwd2: !pwd2 });
    };
    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };

    // guardar nuevo administrador
    const submit = async () => {
        if (formValidator()) {
            if (await saveAdminUser(values, setData)) {
                handleClose();
                setValues(ADMIN_FIELDS);
                setInputErr(ADMIN_ERROR_FIELDS);
            }
        }
    };

    // editar administrador existente
    const update = async () => {
        if (formValidator()) {
            if (await updateAdminUser(values, setData)) {
                handleClose();
                setValues(ADMIN_FIELDS);
                setInputErr(ADMIN_ERROR_FIELDS);
            }
        }
    };

    // cerrar modal
    const handleClose = () => {
        setModal(e => ({ ...e, status: false }));
        setValues(ADMIN_FIELDS);
        setInputErr(ADMIN_ERROR_FIELDS);
        setPwdToggle(e => ({ ...e, pwd1: false, pwd2: false }));
    };

    return (
        <Dialog
            open={ status }
            onClose={ handleClose }
            fullWidth={ true }
            maxWidth="lg"
            sx={{ zIndex: 1000 }}
        >
            <DialogTitle>
                {
                    `${ action === 'edit' ? 'Editar ' : 'Agregar'} administrador`
                }
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3.5}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Correo electrónico"
                            size="large"
                            variant="standard"
                            error={ email_err }
                            helperText={ email_err ? "Ingrese correo electrónico" : "" }
                            inputProps={{ maxLength: 100 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                )
                            }}
                            inputRef={ eInputRef }
                            name="email"
                            value={ email }
                            onChange={ handleInputChange } />
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Nombre de usuario"
                            size="large"
                            variant="standard"
                            error={ username_err }
                            helperText={ username_err ? "Ingrese nombre de usuario" : "" }
                            inputProps={{ maxLength: 100 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                )
                            }}
                            inputRef={ uInputRef }
                            name="username"
                            value={ username }
                            onChange={ handleInputChange } />
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Contraseña</InputLabel>
                            <OutlinedInput
                                label="Contraseña"
                                type={ pwd1 ? 'text' : 'password' }
                                error={ pwd1_err }
                                inputProps={{ maxLength: 60 }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ handlePwd1Toggle }
                                            onMouseDown={ handleMouseDownPwd }
                                            edge="end">
                                            {
                                                pwd1 ? <Visibility /> : <VisibilityOff />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                }
                                inputRef={ p1InputRef }
                                name="password"
                                value={ password }
                                onChange={ handleInputChange } />
                            <FormHelperText error style={
                                pwd1_err ? showErrStyle : hideErrStyle
                            }>
                                Ingrese una contraseña válida
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Confirmar contraseña</InputLabel>
                            <OutlinedInput
                                label="Confirmar contraseña"
                                type={ pwd2 ? 'text' : 'password' }
                                error={ pwd2_err }
                                inputProps={{ maxLength: 60 }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password confirm visibility"
                                            onClick={ handlePwd2Toggle }
                                            onMouseDown={ handleMouseDownPwd }
                                            edge="end">
                                            {
                                                pwd2 ? <Visibility /> : <VisibilityOff />
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                }
                                inputRef={ p2InputRef }
                                name="password_c"
                                value={ password_c }
                                onChange={ handleInputChange } />
                            <FormHelperText error style={
                                pwd2_err ? showErrStyle : hideErrStyle
                            }>
                                Las contraseñas no coinciden
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Rol"
                            size="large"
                            variant="standard"
                            error={ role_err }
                            helperText={ role_err ? "Ingrese rol del administrador" : "" }
                            inputProps={{ maxLength: 100 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AssignmentIndIcon />
                                    </InputAdornment>
                                )
                            }}
                            inputRef={ rInputRef }
                            name="role"
                            value={ role }
                            onChange={ handleInputChange } />
                    </Grid>
                </Grid>
            </DialogContent>
            
            <DialogActions>
                <Button sx={{
                    display: action === 'edit' ? 'none' : 'flex'
                }}
                    onClick={ submit }>
                    Registrar
                </Button>
                <Button sx={{
                    display: action === 'edit' ? 'flex' : 'none'
                }}
                    onClick={ update }>
                    Actualizar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserModal;