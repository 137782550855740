import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { AdminContext } from '../../context/AdminContext';

import FilterCategory from './FilterCategory';

import { GOOGLE_DRIVE_CDN } from '../../constants/main_constants';

//MUI
import {
    Container, Grid, Typography, Card, CardMedia, CardContent,
    Box, Divider, Chip, TextField, InputAdornment, FormControl,
    InputLabel, Select, MenuItem, Button, Drawer, AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderTop: `1px solid #CACFD2`,
    borderBottom: `1px solid #CACFD2`,
    '&:before': {
        display: 'none',
    },
}));

const BlogContainer = () => {
    // blog y categorías
    const { data: { blog: userBlog, categories } } = useContext(AdminContext);

    const [item, setItem] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    
    const [searchInput, setSearchInput] = useState('');
    // const [resultsFound, setResultsFound] = useState(true);

    useEffect(() => {
        if (userBlog.length > 0)
            setItem(userBlog);
    }, [userBlog]);

    useEffect(() => {
        if (categories.length > 0)
            setSelectedCategory(categories);
    }, [categories]);

    const handleChangeChecked = (_id) => {
        setSelectedCategory(
            selectedCategory.map((item) =>
                item.category_id === _id ? { ...item, checked: !item.checked } : item
            )
        );
    };

    const applyFilters = () => {
        // categorías seleccionadas
        const checked = selectedCategory
            .filter((item) => item.checked)
            .map((item) => item.category.toLowerCase()
        );
        const filtered = checked.length > 0 ?
            // blog contiene las categorías seleccionadas
            userBlog.filter(({ category }) => {
                return checked.includes(category.toLowerCase()) ? true : false
            }) : userBlog;
        // valor en barra de búsqueda
        if (searchInput !== '')
            setItem(
                filtered.filter(
                    e => e.title.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
                )
            );
        else setItem(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [selectedCategory, searchInput]);

    const [filter, setFilter] = useState('');

    const onChangeFilter = ({ target }) => {
        setFilter(target.value);
        switch (target.value) {
            case '10':
                setItem([...item].sort(function (a, b) {
                    return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
                }));
                break;
            case '20':
                setItem([...item].sort(function (a, b) {
                    return (a.title < b.title) ? 1 : ((b.title < a.title) ? -1 : 0);
                }));
                break;
            default:
                break;
        }
    };

    //Drawer filtros
    const [drawer, setDrawer] = useState(false);
    const handleOpen = () => {
        setDrawer(true);
    };

    const handleClose = () => {
        setDrawer(false);
    };

    //Acorddion
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Container maxWidth="xl" sx={{ my: 5 }}>
                <Typography variant='h5' paragraph>Blog NIN Institute</Typography>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ my: 'auto' }}>
                        <TextField
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            size="small"
                            label="Buscar..."
                            sx={{ width: '100%' }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ my: 'auto' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '10px' }}>
                            <Box sx={{ my: 'auto' }}>
                                <Button variant='contained' endIcon={<FilterListIcon />} onClick={handleOpen}
                                    sx={{ textTransform: 'none' }}
                                >Filtros</Button>
                            </Box>
                            <Box sx={{ my: 'auto' }}>
                                <FormControl size="small">
                                    <InputLabel>Ordenar por:</InputLabel>
                                    <Select
                                        name={filter}
                                        label="Ordenar por:"
                                        defaultValue={filter}
                                        value={filter}
                                        sx={{ width: '180px' }}
                                        onChange={onChangeFilter}
                                    >
                                        <MenuItem value={'10'}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Nombre A - Z</MenuItem>
                                        <MenuItem value={'20'}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Nombre Z - A</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    {
                        item.length > 0 ?
                            <>
                                {
                                    item.map(({ title, url, category, imgId, preview }, index) => (
                                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                                            <Card sx={{ width: '100%' }}>
                                                <CardMedia
                                                    sx={{ height: 230 }}
                                                    image={`${GOOGLE_DRIVE_CDN}${imgId}`}
                                                    title={title}
                                                    component={Link}
                                                    to={`/blog/${url}`}
                                                />
                                                <CardContent>
                                                    <Typography sx={{ fontSize: '18px', fontWeight: '600' }}
                                                        component="div" gutterBottom>
                                                        {title}
                                                    </Typography>
                                                    <Typography variant='body2' color='text.secondary'>
                                                        {`${preview}...`} <Link to={`/blog/${url}`}>Ver más</Link>
                                                    </Typography>
                                                    <Divider sx={{ my: 2 }} />
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px' }}>
                                                        <Chip label={ category } color="primary" size='small' sx={{ fontWeight: '600' }}></Chip>
                                                        {/* {
                                                            category.map((e, j) => (
                                                                <Chip label={e} key={j} color="primary" size='small' sx={{ fontWeight: '600' }}></Chip>
                                                            ))
                                                        } */}
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                            </>
                        :
                            <Grid item xs={12} sx={{ textAlign: 'center', my: 5 }}>
                                <ManageSearchIcon sx={{ fontSize: '40px' }} />
                                <Typography variant='h5'>¡Lo sentimos! No se ha encontrado ningún resultado.</Typography>
                                <Typography>No hemos podido encontrar lo que buscaba, Intente de nuevo.</Typography>
                            </Grid>
                    }
                </Grid>
            </Container>

            <Drawer
                anchor="right"
                open={drawer}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: '250px' },
                }}
            >
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Categorías
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            selectedCategory.map((_category) => (
                                <FilterCategory
                                    key={_category.category_id}
                                    categoryObj={ _category }
                                    handleChangeChecked={ handleChangeChecked }
                                />
                            ))
                        }
                    </AccordionDetails>
                </Accordion>
                <Box sx={{ mt: 1.5, px: 2 }}>
                    <Button onClick={handleClose} variant='contained'
                        sx={{ width: '100%' }}
                    >Filtrar</Button>
                    <Box sx={{ mt: 1 }}>
                        <Button onClick={() => { setSelectedCategory(categories) }} variant='contained'
                            sx={{ width: '100%' }}
                        >Limpiar filtros</Button>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default BlogContainer;