import React, { useState, useContext } from 'react';

import { AdminContext } from '../../../context/AdminContext';

import './adminusers.css';
import UserModal from './UserModal';
import UserListItem from './UserListItem';

import { ACTIVE_ADMIN_DEFAULT } from '../../../constants/admin/user_constants';

import { Grid, Alert, Fab, Container, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const UserList = () => {
    const { data: { adminUsers } } = useContext(AdminContext);

    const [activeAdmin, setActiveAdmin] = useState(ACTIVE_ADMIN_DEFAULT);
    const [modal, setModal] = useState({
        status: false,
        action: 'add'
    });

    // registrar administrador
    const addAdminUser = () => {
        setModal(e => ({ ...e, status: true, action: 'add' }))
    };
    
    return (
        <>
            <Container maxWidth='xl' sx={{ mt: '90px' }}>
                <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>
                    Lista de administradores
                </Typography>
                {/* <Grid container spacing={4}>
                    {
                        adminUsers.length > 0 ?
                            adminUsers.map(admin =>
                                <UserListItem
                                    key={ admin.user_id }
                                    admin={ admin }
                                    setModal={ setModal }
                                    setActiveAdmin={ setActiveAdmin }
                                />
                            )
                        :
                            <Alert severity="warning">No hay administradores</Alert>
                    }
                </Grid> */}
                    {
                        adminUsers.length > 0 ?
                            <Grid container spacing={4}>
                                {
                                    adminUsers.map(admin =>
                                        <UserListItem
                                            key={ admin.user_id }
                                            admin={ admin }
                                            setModal={ setModal }
                                            setActiveAdmin={ setActiveAdmin }
                                        />
                                    )
                                }
                            </Grid>
                        :
                            <Alert severity="warning">No hay administradores</Alert>
                    }
                
            </Container>
            <Fab color="primary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                onClick={ addAdminUser }>
                <AddIcon />
            </Fab>
            <UserModal
                activeAdmin={ activeAdmin }
                modal={ modal }
                setModal={ setModal }
            />
            {/* <AdminDialog /> */}
        </>
    );
};

export default UserList;