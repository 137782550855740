import React from 'react'

//CSS
import './NinAcademy.css'

//Images
import img_desktop from '../../Assets/Images/1440x782/banner_ninAcademy.jpg'
import img_tablet from '../../Assets/Images/1600x900/Webinar.jpg'
import img_mobile from '../../Assets/Images/1080x920/Webinar.jpg'
import img_webinars from '../../Assets/Images/1440x782/img_3.jpg'
import img_capacit from '../../Assets/Images/1440x782/img_2.jpg'
import img_ponenc from '../../Assets/Images/1440x782/img_1.jpg'
import img_charlas from '../../Assets/Images/1440x782/img_4.jpg'

//MUI
import { Box, Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NinAcademy = () => {
    return (
        <>
            <div className="position-relative-ninAdmy">
                <Box>
                    <div className='banner-ninAdmy-desktop'>
                        <img src={img_desktop} alt={img_desktop} width='100%' />
                    </div>

                    <div className='banner-ninAdmy-tablet'>
                        <img src={img_tablet} alt={img_tablet} width='100%' />
                    </div>

                    <div className='banner-ninAdmy-mobile'>
                        <img src={img_mobile} alt={img_mobile} width='100%' />
                    </div>
                </Box>

                <div className="position-absolute-ninAdmy image-container-ninAdmy">
                    <div className='card_floating-ninAdmy'></div>
                </div>
            </div>

            <Container maxWidth='xl' sx={{ mb: 6, mt: 2 }} id='ninAdmy_page'>
                <Box sx={{ mb: 3 }}>
                    <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                        Webinars
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Explora nuestros webinars, diseñados para brindarte información detallada sobre los temas más relevantes en la
                        actualidad. Nuestros webinars te mantendrán al día con las últimas tendencias y conocimientos de expertos.
                    </Typography>
                    <Link to='/webinars' style={{ color: '#666666', fontWeight: 'bold', fontSize: '13px', cursor: 'pointer', textDecoration: 'none' }}>
                        Ver más +
                    </Link>
                    <img src={img_webinars} alt={img_webinars} width='100%' className='ninAcademy_img' />
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                        Capacitaciones
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Participa en nuestras capacitaciones, diseñadas para potenciar tus habilidades y conocimientos en áreas clínicas
                        específicas. Nuestros programas de capacitación están dirigidos por profesionales con experiencia, que te guiarán hacia
                        un crecimiento profesional significativo y un dominio más profundo de tu campo de interés.
                    </Typography>
                    <Link to='/capacitaciones' style={{ color: '#666666', fontWeight: 'bold', fontSize: '13px', cursor: 'pointer', textDecoration: 'none' }}>
                        Ver más +
                    </Link>
                    <img src={img_capacit} alt={img_capacit} width='100%' className='ninAcademy_img' />
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                        Ponencias
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Descubre perspectivas únicas y conocimientos profundos en nuestras ponencias, presentadas por líderes de la industria y
                        expertos reconocidos. A través de sus experiencias y consejos valiosos, nuestras ponencias te inspirarán a alcanzar nuevas
                        alturas en tu desarrollo profesional.
                    </Typography>
                    <Link to='/ponencias' style={{ color: '#666666', fontWeight: 'bold', fontSize: '13px', cursor: 'pointer', textDecoration: 'none' }}>
                        Ver más +
                    </Link>
                    <img src={img_ponenc} alt={img_ponenc} width='100%' className='ninAcademy_img' />
                </Box>
                <Box>
                    <Typography variant='h4' sx={{ color: '#00CE91', fontWeight: '900' }} gutterBottom>
                        Charlas
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Sumérgete en nuestras charlas profesionales, donde exploramos diversos temas de la industria. Nuestros oradores te guiarán
                        proporcionándote las herramientas necesarias para alcanzar tu máximo potencial.
                    </Typography>
                    <Link to='/charlas' style={{ color: '#666666', fontWeight: 'bold', fontSize: '13px', cursor: 'pointer', textDecoration: 'none' }}>
                        Ver más +
                    </Link>
                    <img src={img_charlas} alt={img_charlas} width='100%' className='ninAcademy_img' />
                </Box>
            </Container>
        </>
    )
}

export default NinAcademy