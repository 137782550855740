import { basicMsg, sweetMsg } from "../helpers/admin/msgHelper";
import { protectedReq, request } from "../helpers/request/requestHelper";

export const signUp = async (model, userLogin) => {
    try {
        const { name, ...obj } = model;
        const resp = await request('user/sign-up', { username: name, ...obj }, 'POST');
        const body = await resp.json();
        if (body.status)
            userLogin(body.resp, true);
        else {
            const { msg } = body;
            if (msg === 'email-exists')
                sweetMsg('info', 'Registro',
                'El correo electrónico ya está en uso');
            else if (msg === 'miss')
                sweetMsg('warning', 'Registro',
                'Falta información de usuario');
            else if (msg === 'sql-err')
                sweetMsg('error', 'Registro',
                'No se pudo registrar su usuario');
            else if (msg === 'server-err')
                sweetMsg('error', 'Registro',
                'El servidor ha dejado de responder');
            else
                basicMsg('Estamos teniendo inconvenientes para registrarlo en el sitio');
        }
    } catch (err) {
        sweetMsg('error', 'Registro',
        'Recargue la página');
    };
};

export const login = async (model, userLogin) => {
    try {
        const resp = await request('user/login', model, 'POST');
        const body = await resp.json();
        if (body.status)
            userLogin(body.resp, true);
        else {
            const { msg } = body;
            if (msg === 'miss')
                sweetMsg('info', 'Inicio de sesión',
                'Falta información para iniciar sesión');
            else if (msg === 'no-results')
                sweetMsg('info', 'Inicio de sesión',
                'No se ha encontrado su usuario');
            else if (msg === 'sql-err')
                sweetMsg('error', 'Inicio de sesión',
                'No se pudo iniciar sesión');
            else if (msg === 'server-err')
                sweetMsg('error', 'Inicio de sesión',
                'El servidor ha dejado de responder');
            else
                basicMsg('Estamos teniendo inconvenientes para iniciar su sesión');
        }
    } catch (err) {
        sweetMsg('error', 'Inicio de sesión',
        'Recargue la página');
    };
};

// validar token almacenado
export const userSession = async (userLogin, stopLoading) => {
    try {
        const resp = await protectedReq('account/renew');
        const body = await resp.json();
        if(body.status)
            userLogin(body.resp, false);
        else {
            if (['unavailable-user-token', 'invalid-user-token'].includes(body.msg)) {
                localStorage.removeItem('token');
                localStorage.removeItem('token-date');
            }
            stopLoading('');
        }
    } catch (err) {
        stopLoading('');
    }
};